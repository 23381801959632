import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import * as styles from './Group.module.css';

const Group = ({ children, className, tag = 'div', ...props }) => {
    const TagName = tag;
    const classNames = cx(styles.group, {
        [className]: className,
    });
    return (
        <TagName {...props} className={classNames}>
            {children}
        </TagName>
    );
};

Group.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    tag: PropTypes.node,
};

export default Group;
