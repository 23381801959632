import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import cx from 'classnames';
import SvgIcon from 'stillnovel/components/UI/SvgIcon';
import { sanitizeString } from 'stillnovel/utils';

import * as styles from './Input.module.css';

const Input = forwardRef(
    (
        {
            className,
            error,
            id,
            label,
            labelTheme,
            name,
            readOnly,
            showErrorText,
            stretch,
            touched,
            type,
            inline,
            pattern,
            theme,
            value = '',
            ...props
        },
        ref
    ) => {
        const classNames = cx(styles.input, styles[theme], className, {
            [styles.stretch]: stretch,
            [styles.inline]: inline,
            [styles.textarea]: type === 'textarea',
            [styles['has-error']]: touched && error,
        });

        if (type === 'radio' || type === 'checkbox') {
            return (
                <span
                    className={cx(
                        type === 'radio' ? styles.radio : styles.checkbox,
                        className,
                        {
                            [styles.inline]: inline,
                        }
                    )}
                >
                    <input
                        {...props}
                        ref={ref}
                        id={id || name}
                        className={styles.input}
                        type={type}
                        name={name}
                        value={sanitizeString(value)}
                        checked={props.checked}
                        onChange={props.onChange}
                    />
                    <label
                        htmlFor={!readOnly ? id || name : null}
                        className={cx(styles.label, labelTheme)}
                    >
                        {label}
                    </label>
                </span>
            );
        }

        if (type === 'select') {
            return (
                <div
                    className={cx(
                        styles.select,
                        touched && error && styles['has-error']
                    )}
                >
                    <select
                        {...props}
                        ref={ref}
                        id={id || name}
                        name={name}
                        className={classNames}
                        required
                        value={sanitizeString(value)}
                        onChange={props.onChange}
                    >
                        {props.children}
                    </select>
                    <SvgIcon
                        iconType="chevron"
                        className={styles['select-arrow']}
                    />
                </div>
            );
        }

        if (type === 'textarea') {
            return (
                <>
                    <textarea
                        {...props}
                        ref={ref}
                        id={id || name}
                        name={name}
                        className={classNames}
                        value={sanitizeString(value)}
                        onChange={props.onChange}
                    />
                    {showErrorText && touched && error && (
                        <span className={cx('body1', 'error-text')}>
                            {error}
                        </span>
                    )}
                </>
            );
        }

        return (
            <>
                <input
                    {...props}
                    ref={ref}
                    id={id || name}
                    pattern={pattern}
                    name={name}
                    type={type}
                    className={classNames}
                    spellCheck="false"
                    value={sanitizeString(value)}
                    onChange={props.onChange}
                />
                {showErrorText && touched && error && (
                    <span className={cx('body1', 'error-text')}>{error}</span>
                )}
            </>
        );
    }
);

Input.displayName = 'Input';

Input.propTypes = {
    className: PropTypes.string,
    error: PropTypes.string,
    id: PropTypes.string,
    inline: PropTypes.bool,
    label: PropTypes.string,
    labelTheme: PropTypes.string,
    name: PropTypes.string,
    pattern: PropTypes.any,
    readOnly: PropTypes.bool,
    showErrorText: PropTypes.bool,
    stretch: PropTypes.bool,
    theme: PropTypes.oneOf(Object.keys(styles)),
    touched: PropTypes.bool,
    type: PropTypes.string,
    value: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
};

export default Input;
