import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import cx from 'classnames';
import Section from 'stillnovel/components/UI/Section';
import { useGraph } from 'stillnovel/hooks/useContentful';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import * as styles from './Summary.module.css';

const CART_CALLOUT_QUERY = `
    query {
        blockCartCalloutCollection(preview: false) {
            items {
                richText {
                    json
                }
            }
        }
    }
`;

const richTextOptions = {
    renderNode: {
        paragraph: (_, children) => <p className="body1">{children}</p>,
        hyperlink: node => (
            <a href={node.data.uri} className={styles.link}>
                {node.content[0].value}
            </a>
        ),
    },
};

function Summary({ cta, totalSub }) {
    const cartCalloutContent = useGraph({
        query: CART_CALLOUT_QUERY,
    });

    const richText = useMemo(
        () =>
            cartCalloutContent?.data?.blockCartCalloutCollection?.items[0]
                ?.richText?.json,
        [cartCalloutContent]
    );

    return (
        <div className={styles.root}>
            <Section
                className={cx(styles['cart-row'], styles['cart-row-sub-total'])}
            >
                <div className={styles['cart-rows']}>
                    <ul role="list">
                        <li
                            className={cx(
                                styles['cart-sub-row'],
                                styles['cart-sub-row-sub-total']
                            )}
                        >
                            <span className="body1-alt">Sub-total</span>
                            <span className="body1-alt">{totalSub}</span>
                        </li>
                        {richText && (
                            <li
                                className={`${styles['shipping-message']} body1 warning-text`}
                            >
                                {documentToReactComponents(
                                    richText,
                                    richTextOptions
                                )}
                            </li>
                        )}
                    </ul>
                </div>
            </Section>
            {cta && cta()}
        </div>
    );
}

Summary.propTypes = {
    cta: PropTypes.func,
    isLoading: PropTypes.bool,
    totalSub: PropTypes.string,
};

export default Summary;
