import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Field from 'redux-form/es/Field';
import reduxForm from 'redux-form/es/reduxForm';
import StepNav from 'stillnovel/components/App/Project/Builders/ArtPrint/StepNav';
import { Container, Col, Row } from 'stillnovel/components/UI/Grid';
import DateInput from 'stillnovel/components/UI/Input/DateInput';
import FieldInput from 'stillnovel/components/UI/Input/FieldInput';
import Form from 'stillnovel/components/UI/Form';
import FormHeader from 'stillnovel/components/App/Project/Builders/ArtPrint/FormHeader';
import NavArrows from 'stillnovel/components/UI/NavArrows';
import PlacesAutocomplete from 'stillnovel/components/UI/PlacesAutocomplete';

import validate from '../validate';

let Details = ({
    handleSubmit,
    invalid,
    previousPage,
    project,
    submitting,
}) => {
    return (
        <Fragment>
            <Form>
                <FormHeader heading="Wedding Details" />
                <Container>
                    <Row>
                        <Col>
                            <label
                                htmlFor="ceremonyLocation"
                                className={'form-title'}
                            >
                                City and State
                            </label>
                            <Field
                                id="ceremonyLocationData"
                                name="ceremonyLocationData"
                                component={PlacesAutocomplete}
                                placeholder="Ex: “Los Angeles, CA”"
                                autoComplete={`${project.id}-ceremonyLocation`}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label
                                htmlFor="ceremonyVenue"
                                className={'form-title'}
                            >
                                Ceremony Venue
                            </label>
                            <Field
                                id="ceremonyVenue"
                                name="ceremonyVenue"
                                component={FieldInput}
                                placeholder="Ex: “St”. Andrews Church"
                                maxLength="23"
                                autoComplete={`${project.id}-ceremonyVenue`}
                                autoCorrect="off"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label
                                htmlFor="receptionPlace"
                                autoCorrect="off"
                                className={'form-title'}
                            >
                                Reception Venue
                            </label>
                            <Field
                                id="receptionPlace"
                                name="receptionPlace"
                                component={FieldInput}
                                placeholder="If at the same location, mention where. Ex: “The Garden”"
                                maxLength="23"
                                autoComplete={`${project.id}-receptionPlace`}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label htmlFor="thought" className={'form-title'}>
                                A Thought (optional)
                            </label>
                            <Field
                                stretch
                                id="thought"
                                name="thought"
                                type="textarea"
                                component={FieldInput}
                                maxLength="124"
                                showErrorText
                                placeholder="A sentence or two about what you think made this day special."
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label
                                htmlFor="experienceWord"
                                className={'form-title'}
                            >
                                The day was:
                            </label>
                            <Field
                                stretch
                                id="experienceWord"
                                name="experienceWord"
                                type="text"
                                component={FieldInput}
                                maxLength="19"
                                placeholder='Ex: "Perfect" (use a single word)'
                            />
                        </Col>
                        <Col>
                            <label
                                className={'form-title'}
                                htmlFor="weddingDate"
                            >
                                Wedding Date &amp; Time
                            </label>
                            <Field
                                name="weddingDate"
                                type="select"
                                component={DateInput}
                                showTime
                            />
                        </Col>
                    </Row>
                </Container>
            </Form>
            <StepNav>
                <NavArrows
                    buttons={[
                        { onClick: previousPage },
                        {
                            onClick: handleSubmit,
                            disabled: invalid || submitting,
                        },
                    ]}
                />
            </StepNav>
        </Fragment>
    );
};

Details.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
    previousPage: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    submitting: PropTypes.bool,
};

Details = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
    touchOnChange: true,
    validate,
})(Details);

export default Details;
