import React, { Component } from 'react';
import PropTypes from 'prop-types';
import getConfig from 'core/config';
import Button from 'stillnovel/components/UI/Button';
import * as Sentry from '@sentry/react';

import * as styles from './ErrorBoundary.module.css';

export default class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidMount() {
        window.addEventListener(
            'sagaError',
            e => {
                console.log('error', e.detail);
                this.setState({ hasError: true, error: e.detail });
            },
            false
        );
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // TODO throw global error message
        Sentry.withScope(scope => {
            Object.keys(errorInfo).forEach(key => {
                scope.setExtra(key, errorInfo[key]);
            });
            Sentry.captureException(error);
        });

        this.setState({ error });
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div className={styles['crash-overlay']}>
                    <div className={styles['crash-overlay-inner']}>
                        <div className={styles['crash-overlay-title']}>
                            <span className="display">
                                ⚠️
                                <br />
                                Gilfoyle!
                            </span>
                            <p className="body1">
                                We&#x27;re currently experiencing technical
                                issues. Try refreshing your browser. If the
                                problem persists, we&#x27;d love to hear from
                                you.
                            </p>
                        </div>
                        <Button
                            theme="block"
                            href="?reload"
                            className={styles['crash-overlay-button']}
                        >
                            Reload the page
                        </Button>
                        <Button
                            theme="block"
                            outline
                            className={styles['crash-overlay-button']}
                            onClick={() => window.Reamaze.popup()}
                        >
                            Support + Chat
                        </Button>
                        {getConfig('debug') !== true && (
                            <Button
                                theme="block"
                                outline
                                className={styles['crash-overlay-button']}
                                onClick={() => Sentry.showReportDialog()}
                            >
                                Report feedback
                            </Button>
                        )}
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.node,
};
