import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Field from 'redux-form/es/Field';
import reduxForm from 'redux-form/es/reduxForm';
import { useSelector, useDispatch } from 'react-redux';
import { change, unregisterField } from 'redux-form';

import StepNav from 'stillnovel/components/App/Project/Builders/ArtPrint/StepNav';
import { Container, Col, Row } from 'stillnovel/components/UI/Grid';
import FieldInput from 'stillnovel/components/UI/Input/FieldInput';
import Form from 'stillnovel/components/UI/Form';
import FormHeader from 'stillnovel/components/App/Project/Builders/ArtPrint/FormHeader';
import NavArrows from 'stillnovel/components/UI/NavArrows';
import PlacesAutocomplete from 'stillnovel/components/UI/PlacesAutocomplete';
import { FieldSelect } from 'stillnovel/components/UI/Input';
import Button from 'stillnovel/components/UI/Button';
import FieldRadio from 'stillnovel/components/UI/Input/FieldRadio';

import CloseButton from 'stillnovel/components/UI/CloseButton';

import validate from '../validate';

import * as styles from './Details.module.css';

const MAX_COUNTS = {
    child: 10,
    grandchild: 20,
};

const Details = ({
    handleSubmit,
    invalid,
    metadata,
    previousPage,
    project,
    submitting,
}) => {
    const dispatch = useDispatch();
    const { subject, style } = metadata;
    const isGrandpa = subject === 'grandpa';
    const isVintage = style === 'vintage';

    // Helper function to extract indices from metadata
    const getIndicesFromMetadata = (prefix, maxCount) =>
        Array.from({ length: maxCount }, (_, i) =>
            metadata[`${prefix}${i + 1}Name`] ? i + 1 : null
        ).filter(Boolean);

    // Initialize indices
    const [indices, setIndices] = useState({
        child: getIndicesFromMetadata('child', MAX_COUNTS.child) || [1],
        grandchild: getIndicesFromMetadata(
            'grandchild',
            MAX_COUNTS.grandchild
        ) || [1],
    });

    // Generic function to handle adding entries
    const handleAddEntry = type => event => {
        event.preventDefault();
        if (indices[type].length < MAX_COUNTS[type]) {
            const nextIndex = indices[type].length
                ? Math.max(...indices[type]) + 1
                : 1;
            setIndices(prev => ({
                ...prev,
                [type]: [...prev[type], nextIndex],
            }));
        }
    };

    // Generic function to handle removing entries
    const handleRemoveEntry = (type, index) => {
        if (indices[type].length > 1) {
            setIndices(prev => ({
                ...prev,
                [type]: prev[type].filter(i => i !== index),
            }));
            dispatch(change('project', `${type}${index}Name`, undefined));
            if (type === 'child') {
                dispatch(change('project', `${type}${index}Gender`, undefined));
                dispatch(unregisterField('project', `${type}${index}Gender`));
            }
            dispatch(unregisterField('project', `${type}${index}Name`));
        }
    };

    useEffect(() => {
        // Ensure at least one field is displayed
        Object.keys(indices).forEach(type => {
            if (!indices[type].length) {
                setIndices(prev => ({ ...prev, [type]: [1] }));
            }
        });
    }, [indices]);

    const luckyNumberOrCustom = useSelector(
        state => state?.form?.project?.values?.luckyNumberOrCustom || false
    );

    const normalizeLength = value =>
        value ? value.replace(/[^0-9]/g, '') : value;

    // Generic function to render entry fields
    const renderEntries = (type, label) => (
        <Row>
            <Col>
                <span className={'form-title'}>
                    {`List ${isGrandpa ? 'his' : 'her'} ${label}`}
                </span>
                <ul className={styles.childList} role="list">
                    {indices[type].map((index, i) => (
                        <li key={index} className={styles.childItem}>
                            <div className={styles.childField}>
                                <label
                                    className={cx('form-title', 'body1')}
                                    htmlFor={`${type}${index}Name`}
                                >
                                    {`${type} #${i + 1}`}
                                </label>
                                <Field
                                    name={`${type}${index}Name`}
                                    type="text"
                                    component={FieldInput}
                                    placeholder="First Name"
                                    autoComplete={`${project.id}-${type}${index}Name`}
                                    autoCorrect="off"
                                />
                                {type === 'child' && (
                                    <Field
                                        name={`${type}${index}Gender`}
                                        component={FieldSelect}
                                        stretch
                                    >
                                        <option value="" disabled>
                                            Select Gender Icon
                                        </option>
                                        <option value="female">Female</option>
                                        <option value="male">Male</option>
                                    </Field>
                                )}
                                <CloseButton
                                    disabled={indices[type].length < 2}
                                    onClick={() =>
                                        handleRemoveEntry(type, index)
                                    }
                                />
                            </div>
                        </li>
                    ))}
                </ul>
                <Button
                    xsmall
                    block
                    className={`${styles.addChildButton} body1-alt`}
                    disabled={indices[type].length >= MAX_COUNTS[type]}
                    onClick={handleAddEntry(type)}
                >
                    + Add more
                </Button>
            </Col>
        </Row>
    );

    return (
        <Fragment>
            <Form>
                <FormHeader heading="Details" />
                <Container>
                    {renderEntries('child', 'children')}
                    {renderEntries('grandchild', 'grandchildren')}
                    {!isVintage && (
                        <Row>
                            <Col>
                                <label
                                    htmlFor="luckyNumberOrCustom"
                                    className={'form-title'}
                                >
                                    Numerology
                                </label>
                            </Col>
                            <Col>
                                <Field
                                    name="luckyNumberOrCustom"
                                    component={FieldRadio}
                                    label="Lucky Number"
                                    type="boolean"
                                    checked={
                                        luckyNumberOrCustom === 'lucky' ||
                                        !luckyNumberOrCustom
                                    }
                                    id="lucky"
                                />
                            </Col>
                            <Col>
                                <Field
                                    name="luckyNumberOrCustom"
                                    component={FieldRadio}
                                    label="Custom"
                                    type="boolean"
                                    checked={luckyNumberOrCustom === 'custom'}
                                    id="custom"
                                />
                                {luckyNumberOrCustom === 'custom' && (
                                    <>
                                        <br />
                                        <Field
                                            id="luckyNumberCustomNumber"
                                            name="luckyNumberCustomNumber"
                                            type="text"
                                            component={FieldInput}
                                            placeholder="Ex: 57"
                                            autoComplete={`${project.id}-luckyNumberCustomNumber`}
                                            autoCorrect="off"
                                            inputMode="numeric"
                                            pattern="[0-9]*"
                                            normalize={normalizeLength}
                                        />
                                    </>
                                )}
                            </Col>
                        </Row>
                    )}
                    <Row alignment="bottom">
                        <Col>
                            <label htmlFor="thought" className={'form-title'}>
                                {`A note to ${isGrandpa ? 'Grandpa' : 'Grandma'}`}
                            </label>
                            <Field
                                stretch
                                id="thought"
                                name="thought"
                                type="textarea"
                                component={FieldInput}
                                maxLength="124"
                                showErrorText
                                placeholder={`A sentence or two sharing what ${
                                    isGrandpa ? 'he' : 'she'
                                } means to you. Ex: a thank-you note.`}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label
                                htmlFor="residenceLocationData"
                                className={'form-title'}
                            >
                                {`Where ${isGrandpa ? 'he' : 'she'} lives`}
                            </label>
                            <Field
                                name="residenceLocationData"
                                component={PlacesAutocomplete}
                                placeholder='Ex: "Los Angeles, CA"'
                                autoComplete={`${project.id}-residenceLocationData`}
                            />
                        </Col>
                    </Row>
                </Container>
            </Form>
            <StepNav>
                <NavArrows
                    buttons={[
                        { onClick: previousPage },
                        {
                            onClick: handleSubmit,
                            disabled:
                                invalid ||
                                submitting ||
                                indices.child.length < 1 ||
                                indices.grandchild.length < 1,
                        },
                    ]}
                />
            </StepNav>
        </Fragment>
    );
};

Details.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
    metadata: PropTypes.shape({
        subject: PropTypes.string,
        style: PropTypes.string,
    }),
    previousPage: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    submitting: PropTypes.bool,
};

export default reduxForm({
    form: 'project',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
    touchOnChange: true,
    validate,
})(Details);
