import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Field from 'redux-form/es/Field';
import reduxForm from 'redux-form/es/reduxForm';
import Form from 'stillnovel/components/UI/Form';
import NavArrows from 'stillnovel/components/UI/NavArrows';
import FieldInput from 'stillnovel/components/UI/Input/FieldInput';
import { Col, Row } from 'stillnovel/components/UI/Grid';

import FormHeader from '../../../FormHeader';
import StyleSelector from '../../shared/StyleSelector';
import StepNav from '../../../StepNav';
import validate from '../validate';

let BackgroundForm = ({
    handleSubmit,
    invalid,
    previousPage,
    project,
    submitting,
}) => {
    return (
        <Fragment>
            <Form>
                <FormHeader heading="Theme" />
                <StyleSelector />
                <Row alignment="bottom">
                    <Col>
                        <label htmlFor="firstName" className={'form-title'}>
                            Name
                        </label>
                        <Field
                            id="firstName"
                            name="firstName"
                            type="text"
                            component={FieldInput}
                            placeholder="First"
                            autoComplete={`${project.id}-firstName`}
                            autoCorrect="off"
                        />
                    </Col>
                    <Col>
                        <Field
                            name="middleName"
                            type="text"
                            component={FieldInput}
                            placeholder="Middle"
                            autoComplete={`${project.id}-middleName`}
                            autoCorrect="off"
                        />
                    </Col>
                    <Col>
                        <Field
                            name="lastName"
                            type="text"
                            component={FieldInput}
                            placeholder="Last"
                            autoComplete={`${project.id}-lastName`}
                            autoCorrect="off"
                        />
                    </Col>
                </Row>
            </Form>
            <StepNav>
                <NavArrows
                    buttons={[
                        { onClick: previousPage, disabled: true },
                        {
                            onClick: handleSubmit,
                            disabled: invalid || submitting,
                        },
                    ]}
                />
            </StepNav>
        </Fragment>
    );
};

BackgroundForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
    metadata: PropTypes.object.isRequired,
    previousPage: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    submitting: PropTypes.bool,
};

BackgroundForm = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
    touchOnChange: true,
    validate,
})(BackgroundForm);

export default BackgroundForm;
