import React, { memo } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import ArtPrintPreview from 'stillnovel/components/App/ArtPrintPreview';
import { Cell, Row } from 'stillnovel/components/UI/Section';
import Button from 'stillnovel/components/UI/Button';
import CloseButton from 'stillnovel/components/UI/CloseButton';
import Figure from 'stillnovel/components/UI/Figure';
import Group from 'stillnovel/components/UI/Group';
import Input from 'stillnovel/components/UI/Input';
import {
    removeCartItem,
    updateCartItemQty,
} from 'stillnovel/store/cart/actions';

import * as styles from './CartItem.module.css';

function CartItem(item) {
    const { id, quantity, variant, title, customAttributes } = item;

    const classNames = cx(styles['cart-item'], {
        [styles['is-pending']]: !id,
    });

    const dispatch = useDispatch();

    const projectId = customAttributes.find(
        attr => attr.key === '_projectId'
    )?.value;

    const project = useSelector(state =>
        state.projects.items.find(project => {
            return project.id === projectId;
        })
    );

    return (
        <Row tag="li" className={classNames}>
            <Cell colSpan={4}>
                <Group className={styles['cart-item-title-group']}>
                    <Figure className={styles['cart-item-thumb']}>
                        <ArtPrintPreview
                            {...project}
                            sku={variant.sku}
                            thumbnail
                            inSitu
                        />
                    </Figure>
                    <Group className={styles['cart-item-title-subgroup']}>
                        <span className="body1-alt">
                            {title || ''}
                            <br />
                            {variant?.title}{' '}
                            <Button to={`/project/${projectId}`} underline>
                                (Edit)
                            </Button>
                        </span>
                        <label
                            className={cx(
                                'body1',
                                styles['label-qty'],
                                styles['hide-pending']
                            )}
                            htmlFor="qty"
                        >
                            Qty:{' '}
                            <Input
                                className={cx(
                                    styles['input-qty'],
                                    styles['hide-pending']
                                )}
                                type="select"
                                value={String(quantity)}
                                name="qty"
                                id="qty"
                                onChange={e =>
                                    dispatch(
                                        updateCartItemQty(
                                            id,
                                            parseInt(e.target.value, 10)
                                        )
                                    )
                                }
                            >
                                {[...Array(20).keys()].map(i => (
                                    <option key={i} value={i + 1}>
                                        {i + 1}
                                    </option>
                                ))}
                            </Input>
                        </label>
                    </Group>
                </Group>
            </Cell>
            <Cell colSpan={2}>
                <dl className={styles['cart-item-details-list']}>
                    <dt className="body1">
                        {Number(variant?.price?.amount).toFixed(2)}
                    </dt>
                    {customAttributes
                        .filter(attr => attr.key[0] !== '_')
                        .map((attr, i) => (
                            <dt key={`dd${i}`} className="body1">
                                {attr.key}: {attr.value.replace('-', ' ')}
                            </dt>
                        ))}
                </dl>
            </Cell>

            <CloseButton
                className={cx(styles['close-btn'], styles['hide-pending'])}
                onClick={() => dispatch(removeCartItem(id))}
            />
        </Row>
    );
}

export default memo(CartItem);
