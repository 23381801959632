import React from 'react';
import PropTypes from 'prop-types';
import Page from 'stillnovel/components/UI/Page';

import * as styles from './AuthLayout.module.css';

const AuthLayout = props => {
    return (
        <Page>
            <div className={styles['auth-wrapper']}>
                <article className={styles['auth-content']}>
                    {props.children}
                </article>
            </div>
        </Page>
    );
};

AuthLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default AuthLayout;
