import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import * as styles from './Link.module.css';

const Link = ({ to, ...props }) => {
    if (to) {
        return <NavLink className={styles.root} to={to} {...props} />;
    }

    return <a className={styles.root} {...props} />;
};

Link.propTypes = {
    to: PropTypes.string,
};

export default Link;
