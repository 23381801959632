import React from 'react';
import { Helmet } from 'react-helmet';

import * as styles from './NotFound.module.css';

export default function NotFound() {
    return (
        <div className={styles.root}>
            <Helmet>
                <title>Page not found</title>
            </Helmet>
            <div className={styles.inner}>
                <h1 className="jumbo">Oops!</h1>
                <h3 className="title">
                    We can&#39;t seem to find the page you&#39;re looking for.
                </h3>
            </div>
        </div>
    );
}
