import React, {
    Fragment,
    memo,
    useState,
    useEffect,
    useCallback,
    useContext,
    useRef,
} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Field from 'redux-form/es/Field';
import reduxForm from 'redux-form/es/reduxForm';
import Button from 'stillnovel/components/UI/Button';
import Form from 'stillnovel/components/UI/Form';
import NavArrows from 'stillnovel/components/UI/NavArrows';
import Portal from 'stillnovel/components/UI/Portal';
import SvgIcon from 'stillnovel/components/UI/SvgIcon';

import { ProductContext } from 'stillnovel/components/App/Project/Project';
import {
    disableBodyScroll,
    enableBodyScroll,
    clearAllBodyScrollLocks,
} from 'body-scroll-lock';
import { ImgOptimized } from 'stillnovel/components/ContentfulImg';

import FrameDetail from '../FrameDetail';
import FormHeader from '../../../FormHeader';
import StepNav from '../../../StepNav';

import * as styles from './FrameSelection.module.css';

const Selection = memo(({ variant }) => {
    const selectionRef = useRef(null);
    const [previewIsVisible, setPreviewIsVisible] = useState(false);

    const fields =
        variant?.frameDetail?.reference?.fields &&
        Object.fromEntries(
            variant?.frameDetail?.reference?.fields?.map(field => [
                field.key,
                field,
            ])
        );

    const thumbnail = fields?.thumbnail?.reference?.image;

    const hidePreview = useCallback(
        () =>
            setTimeout(() => {
                setPreviewIsVisible(false);
            }, 300),
        []
    );

    const showPreview = useCallback(() => setPreviewIsVisible(true), []);

    useEffect(() => {
        if (selectionRef.current) {
            previewIsVisible
                ? disableBodyScroll(selectionRef.current)
                : enableBodyScroll(selectionRef.current);
        }
        return () => {
            clearAllBodyScrollLocks();
        };
    }, [previewIsVisible]);

    const soldOut = variant?.availableForSale === false;

    const discontinued = Boolean(variant?.discontinued?.value);

    return (
        <Fragment>
            <li className={styles['options-list-item']}>
                <Field
                    id={variant.id}
                    name="shopifyProductId"
                    component={({ id, input }) => {
                        return (
                            <label
                                className={cx(styles['selection-label'], {
                                    [styles['selection--sold-out']]:
                                        soldOut || discontinued,
                                })}
                            >
                                <input
                                    {...input}
                                    checked={input.value === id || false}
                                    className={styles['selection-radio']}
                                    id={id}
                                    name={input.name}
                                    type="radio"
                                    value={id}
                                />
                                <span className={styles['selection-info']}>
                                    <figure
                                        className={styles['selection-thumb']}
                                    >
                                        {thumbnail?.url && (
                                            <ImgOptimized
                                                className={
                                                    styles[
                                                        'selection-thumb-img'
                                                    ]
                                                }
                                                src={thumbnail?.url}
                                                alt={thumbnail?.altText}
                                                width={thumbnail?.width}
                                                height={thumbnail?.height}
                                                customSources={[
                                                    {
                                                        breakpoint: 768,
                                                        imageWidth: 1920,
                                                    },
                                                    {
                                                        breakpoint: 320,
                                                        imageWidth: 1280,
                                                    },
                                                ]}
                                                onLoad={e =>
                                                    e.target.classList.add(
                                                        styles.loaded
                                                    )
                                                }
                                            />
                                        )}

                                        <figcaption
                                            className={styles.figcaption}
                                        >
                                            <h3
                                                className={cx(
                                                    'jumbo-alt',
                                                    styles[
                                                        'selection-info-title'
                                                    ]
                                                )}
                                            >
                                                <span>
                                                    {input.value === id && '✓ '}
                                                    {variant?.title ||
                                                        fields?.title?.value}
                                                </span>
                                            </h3>
                                            <span className={styles.details}>
                                                {!discontinued && (
                                                    <span
                                                        className={'body1-alt'}
                                                    >
                                                        $
                                                        {Math.round(
                                                            variant?.price
                                                                ?.amount
                                                        )}
                                                    </span>
                                                )}
                                                {soldOut || discontinued ? (
                                                    soldOut ? (
                                                        <span className="body1-alt error-text">
                                                            Currently out of
                                                            stock
                                                        </span>
                                                    ) : (
                                                        <span className="body1-alt">
                                                            Discontinued
                                                        </span>
                                                    )
                                                ) : (
                                                    fields?.photos?.references
                                                        ?.nodes?.length > 0 && (
                                                        <Button
                                                            className={
                                                                styles[
                                                                    'selection-detail-button'
                                                                ]
                                                            }
                                                            onClick={
                                                                showPreview
                                                            }
                                                        >
                                                            <SvgIcon
                                                                iconType="images"
                                                                className={
                                                                    styles.icon
                                                                }
                                                            />{' '}
                                                            <span className="body1-alt">
                                                                View Details
                                                            </span>
                                                        </Button>
                                                    )
                                                )}
                                            </span>
                                        </figcaption>
                                    </figure>
                                </span>
                            </label>
                        );
                    }}
                />
            </li>
            <Portal>
                {previewIsVisible && (
                    <FrameDetail
                        ref={selectionRef}
                        description={fields?.description?.value ?? ''}
                        title={fields?.title?.value}
                        images={fields?.photos?.references?.nodes}
                        hide={hidePreview}
                    />
                )}
            </Portal>
        </Fragment>
    );
});

Selection.displayName = 'Selection';

Selection.propTypes = {
    variant: PropTypes.object.isRequired,
};

let FrameSelection = ({
    handleSubmit,
    inCart,
    isDirty,
    metadata,
    previousPage,
    project = {},
    submitting,
    validate,
    fields,
}) => {
    const invalidFormRef = useRef(null);

    const [invalidForm, setInvalidForm] = useState(false);

    const product = useContext(ProductContext);

    const formFields = validate({
        ...metadata,
        shopifyProductId: project.shopifyProductId,
    });

    const firstInvalidStep = () => {
        // This is a temporary, hardcoded lookup until
        // our build flows are config-based
        // const fields = {
        //     background: ['baseColor', 'imageMeta', 'firstName', 'lastName'],
        //     details: ['birthDate', 'birthPlace', 'dueDate', 'thought'],
        //     stats: ['len', 'weightLbs', 'weightOz'],
        // };
        const page = Object.entries(fields).find(([, pageFields]) => {
            return pageFields.find(key => formFields[key] !== false);
        })?.[0];

        return `../${page === 'default' ? '' : page}`;
    };

    const formIsInvalid = Object.keys(formFields).some(k => formFields[k]);

    const invalidFields = Object.values(formFields).filter(field => {
        return field !== false ? field : null;
    });

    // Only disable submit until they select a frame
    const allowSubmit =
        formFields.shopifyProductId !== false ||
        project.shopifyProductId === product.id; // product.id is default non-variant id

    const invalidFormStatement =
        invalidFields.length > 1
            ? `there are ${invalidFields.length} issues that require`
            : `there is one issue that requires`;

    useEffect(() => {
        if (invalidFormRef.current) {
            invalidForm
                ? disableBodyScroll(invalidFormRef.current)
                : enableBodyScroll(invalidFormRef.current);
        }
        return function cleanup() {
            clearAllBodyScrollLocks();
        };
    }, [invalidForm, invalidFormRef]);

    if (!product) {
        return false;
    }

    return (
        <Fragment>
            {invalidForm && (
                <Portal>
                    <div
                        ref={invalidFormRef}
                        className={styles['invalid-modal']}
                    >
                        <div className={styles['invalid-modal-inner']}>
                            <h2 className="display">Whoops</h2>
                            <p className="body1">
                                Before adding this project to your cart,{' '}
                                {invalidFormStatement} your attention.
                            </p>
                            <Button
                                block
                                to={`${firstInvalidStep()}?validate=true`}
                                onClick={() => setInvalidForm(false)}
                            >
                                Fix {invalidFields.length > 1 ? 'them' : 'it'}{' '}
                                now
                            </Button>
                        </div>
                    </div>
                </Portal>
            )}

            <Form autoComplete="nope" className={styles.root}>
                <FormHeader heading="Finishing Options" />
                <div className={styles.options}>
                    <ul className={styles['options-list']} role="list">
                        {product.variants
                            // Sort by availability
                            .sort((a, b) => {
                                if (
                                    Boolean(a?.discontinued?.value) &&
                                    Boolean(b?.discontinued?.value) === false
                                ) {
                                    return 1;
                                } else if (
                                    Boolean(b?.discontinued?.value) &&
                                    Boolean(a?.discontinued?.value) === false
                                ) {
                                    return -1;
                                } else if (
                                    a.availableForSale &&
                                    !b.availableForSale
                                ) {
                                    return -1;
                                } else if (
                                    !a.availableForSale &&
                                    b.availableForSale
                                ) {
                                    return 1;
                                }
                                return 0;
                            })
                            .map(variant => (
                                <Selection
                                    key={variant.id}
                                    variant={variant}
                                    builderHandle={project.builderHandle}
                                    style={project.metadata?.style}
                                />
                            ))}
                    </ul>
                </div>
            </Form>
            <StepNav>
                <NavArrows
                    buttons={[
                        { onClick: previousPage },
                        {
                            onClick: formIsInvalid
                                ? () => setInvalidForm(true)
                                : handleSubmit,
                            disabled: allowSubmit || submitting || isDirty,
                            label: isDirty
                                ? 'Please wait'
                                : inCart
                                  ? 'Update in Cart'
                                  : 'Add to Cart',
                            targetName: !inCart && 'btn-add-to-cart',
                        },
                    ]}
                />
            </StepNav>
        </Fragment>
    );
};

FrameSelection.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    inCart: PropTypes.bool,
    isDirty: PropTypes.bool,
    metadata: PropTypes.object,
    previousPage: PropTypes.func.isRequired,
    project: PropTypes.object,
    submitting: PropTypes.bool,
    validate: PropTypes.func.isRequired,
    fields: PropTypes.object,
};

FrameSelection = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
    touchOnChange: true,
})(FrameSelection);

export default memo(FrameSelection);
