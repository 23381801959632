import './styles/animations.css';
import './styles/colors.css';
import './styles/fonts.css';
import './styles/keyframes.css';
import './styles/layout.css';
import './styles/typography.css';
import './styles/zindex.css';
import './styles/root.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import Root from 'stillnovel/components/App/Root.js';
import { setupAuth } from 'stillnovel/utils/auth/policy';
import * as Sentry from '@sentry/react';
import getConfig from 'core/config';

import store from './store';

Sentry.init({
    enabled: getConfig('debug') !== true,
    debug: getConfig('debug') === true,
    release: process.env.VERCEL_GIT_COMMIT_SHA || undefined,
    dsn: 'https://dca349fb42164d8da76cceab0b00e381@o4504176056860672.ingest.sentry.io/4504176062627845',
    environment: `${getConfig('sentryEnvironment')}`,
    integrations: [
        Sentry.browserTracingIntegration({
            tracePropagationTargets: [
                // Same-origin requests
                /^\//,

                // Still Novel domains
                /^https?:\/\/(.*\.)?stillnovel\.com/, // All stillnovel.com subdomains

                // Development
                'localhost',

                // Add any third-party APIs you want to trace
                // Example: /^https:\/\/api\.stripe\.com/
            ],
        }),
    ],
    // Adjust sampling rates for performance and cost optimization
    tracesSampleRate: getConfig('debug') === true ? 1.0 : 0.1,
    replaysOnErrorSampleRate: 1.0,

    // Filter and modify events before sending
    beforeSend(event) {
        // Don't send errors from browser extensions
        const browserExtensionErrors = [
            /^chrome-extension:\/\//,
            /^moz-extension:\/\//,
            /^safari-extension:\/\//,
        ];

        if (event.exception && event.exception.values) {
            const filename =
                event.exception.values[0]?.stacktrace?.frames?.[0]?.filename;
            if (
                filename &&
                browserExtensionErrors.some(pattern => pattern.test(filename))
            ) {
                return null;
            }
        }

        // Scrub any potential PII or sensitive data
        if (event.request && event.request.headers) {
            // Remove sensitive headers
            delete event.request.headers.Authorization;
            delete event.request.headers.Cookie;
        }

        return event;
    },
});

setupAuth(store, {});

const rootEl = document.getElementById('root');

const root = createRoot(rootEl);

function render(RootComponent) {
    root.render(<RootComponent store={store} />);
}

render(Root);
