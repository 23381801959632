import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Field from 'redux-form/es/Field';
import reduxForm from 'redux-form/es/reduxForm';
import { useSelector } from 'react-redux';
import { Container, Col, Row } from 'stillnovel/components/UI/Grid';
import FieldSelect from 'stillnovel/components/UI/Input/FieldSelect';
import FieldInput from 'stillnovel/components/UI/Input/FieldInput';
import FieldRadio from 'stillnovel/components/UI/Input/FieldRadio';
import Form from 'stillnovel/components/UI/Form';
import NavArrows from 'stillnovel/components/UI/NavArrows';
import { stripWhitespace } from 'stillnovel/utils';

import FormHeader from '../../../FormHeader';
import validate from '../validate';
import StepNav from '../../../StepNav';

let Stats = ({
    handleSubmit,
    invalid,
    metadata,
    previousPage,
    project,
    submitting,
}) => {
    const withoutAstrology = useSelector(state => {
        return state?.form?.project?.values?.withoutAstrology || false;
    });

    return (
        <Fragment>
            <Form>
                <FormHeader heading="Details (cont.)" />
                <Container>
                    <Row alignment="bottom">
                        <Col>
                            <label
                                htmlFor="withoutAstrology"
                                className={'form-title'}
                            >
                                Astrology
                            </label>
                        </Col>
                        <Col>
                            <Field
                                name="withoutAstrology"
                                component={FieldRadio}
                                label="Include"
                                type="boolean"
                                checked={
                                    withoutAstrology === 'false' ||
                                    !withoutAstrology
                                }
                                id={'false'}
                            />
                        </Col>
                        <Col>
                            <Field
                                name="withoutAstrology"
                                component={FieldRadio}
                                label="Exclude"
                                type="boolean"
                                checked={withoutAstrology === 'true'}
                                id={'true'}
                            />
                        </Col>
                    </Row>
                    {withoutAstrology === 'true' && (
                        <>
                            <Row>
                                <Col>
                                    <label
                                        htmlFor="gender"
                                        className={'form-title'}
                                    >
                                        Gender
                                    </label>
                                    <Field
                                        name="gender"
                                        component={FieldSelect}
                                        stretch
                                    >
                                        <option disabled value="">
                                            --
                                        </option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Omit">Omit</option>
                                    </Field>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <label
                                        htmlFor="personality"
                                        className={'form-title'}
                                    >
                                        {`In a word, you ${
                                            metadata?.isMemorial
                                                ? 'were'
                                                : 'are'
                                        }`}
                                    </label>
                                    <Field
                                        type="text"
                                        name="personality"
                                        component={FieldInput}
                                        placeholder={'Ex: "Charming" or "Bold"'}
                                        autoComplete={`${project.id}-personality`}
                                        autoCorrect="off"
                                        maxLength={16}
                                        normalize={stripWhitespace}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <label
                                        htmlFor="motto"
                                        className={'form-title'}
                                    >
                                        Motto
                                    </label>
                                    <Field
                                        name="motto"
                                        component={FieldSelect}
                                        stretch
                                    >
                                        <option value="" disabled>
                                            --
                                        </option>
                                        <option>I Use</option>
                                        <option>I Know</option>
                                        <option>I Believe</option>
                                        <option>I Am</option>
                                        <option>I Have</option>
                                        <option>I Think</option>
                                        <option>I Feel</option>
                                        <option>I Will</option>
                                        <option>I Analyze</option>
                                        <option>I Balance</option>
                                        <option>I Desire</option>
                                        <option>I Aim</option>
                                    </Field>
                                </Col>
                            </Row>
                        </>
                    )}
                </Container>
            </Form>
            <StepNav>
                <NavArrows
                    buttons={[
                        { onClick: previousPage },
                        {
                            onClick: handleSubmit,
                            disabled: invalid || submitting,
                            label: 'Review',
                        },
                    ]}
                />
            </StepNav>
        </Fragment>
    );
};

Stats.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
    metadata: PropTypes.shape({
        isMemorial: PropTypes.bool,
    }),
    previousPage: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    submitting: PropTypes.bool,
};

Stats = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
    touchOnChange: true,
    validate,
})(Stats);

export default Stats;
