// extracted by mini-css-extract-plugin
var _1 = "WWceSt";
var _2 = "C5TKU4";
var _3 = "OmByJl";
var _4 = "hYI4LN";
var _5 = "pXjmoV";
var _6 = "U37_6Q";
var _7 = "mcQJcA";
var _8 = "G5USJz";
var _9 = "U4UBnO";
var _a = "p_Xgli";
export { _1 as "detail", _2 as "detail--exiting", _3 as "detail-close", _4 as "detail-content", _5 as "detail-description", _6 as "detail-figure", _7 as "detail-header", _8 as "detail-image", _9 as "detail-images", _a as "loaded" }
