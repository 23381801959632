// extracted by mini-css-extract-plugin
var _1 = "mb5jjS";
var _2 = "tAmq1s";
var _3 = "PAvasr";
var _4 = "IUvcg6";
var _5 = "C7gMCA";
var _6 = "oxA09H";
var _7 = "PUDcpI";
var _8 = "UYqbdF";
var _9 = "b3pKmv";
export { _1 as "block", _2 as "btn", _3 as "large", _4 as "outline", _5 as "small", _6 as "underline", _7 as "xlarge", _8 as "xsmall", _9 as "xxlarge" }
