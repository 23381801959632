import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Field from 'redux-form/es/Field';
import reduxForm from 'redux-form/es/reduxForm';
import { Col, Row } from 'stillnovel/components/UI/Grid';
import Form from 'stillnovel/components/UI/Form';
import FieldRadio from 'stillnovel/components/UI/Input/FieldRadio';
import FieldInput from 'stillnovel/components/UI/Input/FieldInput';
import NavArrows from 'stillnovel/components/UI/NavArrows';
import ImageInput from 'stillnovel/components/UI/Input/ImageInput';
import productsConfig from 'stillnovel/components/App/Project/config';

import FormHeader from '../../../FormHeader';
import ColorSelector from '../../shared/ColorSelector';
import StepNav from '../../../StepNav';
import validate from '../validate';

let BackgroundForm = ({
    handleSubmit,
    invalid,
    metadata,
    previousPage,
    project,
    submitting,
}) => {
    const config = productsConfig[project?.builderHandle];

    const photoTargetWidth = config?.printWidth * 72;
    const photoTargetHeight = config?.printHeight * 72;

    const borderSize = useSelector(state => {
        const borderSizeDirty =
            state?.form?.project?.values?.borderSize || 1.25;
        if (borderSizeDirty === 'SIZE_NONE') {
            return 0;
        }
        return parseFloat(
            typeof borderSizeDirty === 'string'
                ? borderSizeDirty.replace(/[^\d.-]/g, '')
                : borderSizeDirty
        );
    });

    const style = useSelector(state => {
        return state?.form?.project?.values?.style;
    });

    const colors = [
        {
            key: 'SOFT_WHITE',
            name: 'Soft White',
            hex: '#fcfaf8',
        },
        {
            key: 'SMOKE',
            name: 'Smoke',
            hex: '#dcdfe4',
            opacity: 1,
        },
        { key: 'NUDE', name: 'Nude', hex: '#f5e9e5', opacity: 1 },
        {
            key: 'GREIGE',
            name: 'Greige',
            hex: '#e9eaea',
            opacity: 1,
        },

        {
            key: 'DEFAULT',
            name: 'No Filter',
            hex: '#efefef',
            opacity: 1,
        },
    ];

    return (
        <Fragment>
            <Form>
                <FormHeader heading="Photo" />
                <Field
                    name="imageMeta"
                    component={ImageInput}
                    targetWidth={photoTargetWidth - borderSize * 2 * 72}
                    targetHeight={photoTargetHeight - borderSize * 2 * 72}
                />
                <Row>
                    <Col colSpan={1}>
                        <label htmlFor="borderSize" className={'form-title'}>
                            Border
                        </label>
                    </Col>
                    <Col colSpan={1}>
                        <Field
                            name="borderSize"
                            component={FieldRadio}
                            checked={borderSize === 1.25}
                            label={`1.25"`}
                            id={'SIZE_1.25'}
                        />
                    </Col>
                    <Col colSpan={2}>
                        <Field
                            name="borderSize"
                            component={FieldRadio}
                            label="None"
                            id={'SIZE_0'}
                        />
                    </Col>
                </Row>
                {borderSize !== 0 && (
                    <Row>
                        <Col colSpan={1}>
                            <label htmlFor="style" className={'form-title'}>
                                Caption Style
                            </label>
                        </Col>
                        <Col colSpan={1}>
                            <Field
                                name="style"
                                component={FieldRadio}
                                checked={style === 'MODERN' || !style}
                                label="Modern (sans-serif)"
                                id={'MODERN'}
                            />
                        </Col>
                        <Col colSpan={2}>
                            <Field
                                name="style"
                                component={FieldRadio}
                                label={`Vintage (serif)`}
                                id={'VINTAGE'}
                            />
                        </Col>
                    </Row>
                )}
                {borderSize !== 0 && (
                    <Row>
                        <Col colSpan={1}>
                            <label htmlFor="caption" className={'form-title'}>
                                Caption
                            </label>
                        </Col>
                        <Col colSpan={4}>
                            <Field
                                name="caption"
                                type="text"
                                component={FieldInput}
                                placeholder=""
                                autoComplete={`${project.id}-caption`}
                                autoCorrect="off"
                            />
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col>
                        <Field
                            name="baseColor"
                            component={ColorSelector}
                            colors={colors}
                            style={metadata.style || 'modern'}
                        />
                    </Col>
                </Row>
            </Form>
            <StepNav>
                <NavArrows
                    buttons={[
                        { onClick: previousPage, disabled: true },
                        {
                            onClick: handleSubmit,
                            disabled: invalid || submitting,
                        },
                    ]}
                />
            </StepNav>
        </Fragment>
    );
};

BackgroundForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
    metadata: PropTypes.object.isRequired,
    previousPage: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    submitting: PropTypes.bool,
};

BackgroundForm = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
    touchOnChange: true,
    validate,
})(BackgroundForm);

export default BackgroundForm;
