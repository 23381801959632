// extracted by mini-css-extract-plugin
var _1 = "VWmZdR";
var _2 = "HqPB7y";
var _3 = "jV4KhS";
var _4 = "EtytQT";
var _5 = "MTyZYF";
var _6 = "gMRWiw";
var _7 = "aWpIVx";
var _8 = "kCdTrK";
var _9 = "eCegvS";
export { _1 as "col-1", _2 as "col-2", _3 as "col-3", _4 as "col-4", _5 as "col-5", _6 as "col-6", _7 as "col-7", _8 as "col-8", _9 as "root" }
