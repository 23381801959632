import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import useSWR from 'swr';
import { useMount } from 'react-use';
import { Helmet } from 'react-helmet';

import Button from 'stillnovel/components/UI/Button';
import AuthTrigger from 'stillnovel/components/App/Auth/Trigger';
import SN from 'stillnovel/services';
import { deleteProject, getProjects } from 'stillnovel/store/projects/actions';
import ProjectTile from 'stillnovel/components/App/Account/Dashboard/ProjectTile';
import Header from 'stillnovel/components/App/Header';
import getConfig from 'core/config';

import * as styles from './Dashboard.module.css';

const Dashboard = () => {
    const dispatch = useDispatch();

    const projects = useSelector(state => state.projects.items);
    const isLoading = useSelector(state => state.projects.isLoading);
    const user = useSelector(state => state.auth.user);
    const isGuestUser = user?.role === 'guest';

    const { data: shopifyProducts } = useSWR('products', () =>
        SN.shopifyClient.product.fetchAll()
    );

    const shopifyProductsByHandle =
        shopifyProducts?.reduce((acc, product) => {
            acc[product.handle] = product;
            product.variants.forEach(variant => {
                acc[variant.id] = variant;
                if (variant.handle) acc[variant.handle] = variant;
            });
            return acc;
        }, {}) || {};

    useMount(() => dispatch(getProjects()));

    useEffect(() => {
        document.body.classList.toggle('loading', isLoading);
    }, [isLoading]);

    const renderProjects = () => {
        const sortedProjects = [...projects].sort(
            (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        );

        if (sortedProjects.length === 0 && isGuestUser && !isLoading) {
            return (
                <>
                    <h4>
                        <Button
                            className="title"
                            to={`${getConfig('marcomBaseUrl')}/products`}
                        >
                            Create your first project
                        </Button>
                    </h4>
                    <ul
                        className={styles.list}
                        style={{ opacity: 0.4 }}
                        role="list"
                    >
                        <ProjectTile project={{}} />
                    </ul>
                </>
            );
        }

        return (
            <ul role="list" className={styles.list}>
                {!isLoading &&
                    sortedProjects.map(project => (
                        <ProjectTile
                            key={project.id}
                            project={project}
                            productsKeyed={shopifyProductsByHandle}
                            deleteProject={deleteProject}
                        />
                    ))}
            </ul>
        );
    };

    const renderGuestMessage = () => (
        <section className={styles.guestUserContent}>
            <h3 className="title">Log in to view your projects</h3>
            <p className="body1">
                You can create, view, or edit projects across all devices once
                you’ve logged in.
            </p>
            <AuthTrigger>
                <Button block>Log in</Button>
            </AuthTrigger>
        </section>
    );

    return (
        <div className={cx(styles.root, { [styles.loading]: isLoading })}>
            <Helmet>
                <title>Projects</title>
            </Helmet>

            <div className={styles.content}>
                <Header headline="My Projects" />

                {isGuestUser && renderGuestMessage()}

                <div className={styles.list}>
                    {projects.length > 0 && (
                        <h4 className="title">
                            {isGuestUser
                                ? 'Recent Drafts'
                                : 'Recently Saved Projects'}
                        </h4>
                    )}
                    {renderProjects()}
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
