import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import Button from 'stillnovel/components/UI/Button';
import Loader from 'stillnovel/components/UI/Loader';
import Section from 'stillnovel/components/UI/Section';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { useSelector, useDispatch } from 'react-redux';
import { clearCheckout } from 'stillnovel/store/cart/actions';

import getConfig from 'core/config';
import Header from 'stillnovel/components/App/Header';

import Summary from '../Summary';

import CartItems from './CartItems';
import * as styles from './Cart.module.css';

function Cart() {
    const [isCheckingOut, setCheckingOut] = useState(false);
    const location = useLocation();
    const dispatch = useDispatch();

    // Memoize query parsing
    const query = useMemo(
        () => qs.parse(location.search.replace('?', '')),
        [location.search]
    );

    // Memoize selectors
    const { lineItems, subtotalPrice, webUrl, loadState, isStale } =
        useSelector(state => ({
            lineItems: state.cart.lineItems,
            subtotalPrice: state.cart.subtotalPrice,
            webUrl: state.cart.webUrl,
            loadState: state.cart.loadState,
            isStale: state.cart.isStale,
        }));

    const canRenderCart =
        loadState === 'CART/LOADED' || loadState === 'CART/LOADING';
    const cartEmpty = !lineItems?.length;
    const isLoading = !canRenderCart;

    // Memoize checkout URL transformation
    const checkoutUrl = useMemo(
        () =>
            webUrl?.replace(
                /stillnovel\.myshopify\.com/gi,
                'shop.stillnovel.com'
            ),
        [webUrl]
    );

    // Handle checkout completion
    useEffect(() => {
        if (query?.action === 'complete') {
            dispatch(clearCheckout());
        }
    }, [query?.action, dispatch]);

    // Memoize handlers
    const handleCheckout = useCallback(() => {
        setCheckingOut(true);
    }, []);

    const renderCartEmpty = useCallback(
        () => (
            <Section>
                <h3 className="title">Your cart is empty.</h3>
                <p className="body1">
                    Start adding items to your cart by browsing our product
                    line.
                </p>
                <Button
                    small
                    block
                    outline
                    to={`${getConfig('marcomBaseUrl')}/products`}
                    className={styles.button}
                >
                    Explore products
                </Button>
            </Section>
        ),
        []
    );

    const renderCart = useCallback(
        () => (
            <React.Fragment>
                <CartItems cartItems={lineItems} subTotal={subtotalPrice} />
                <Summary
                    cta={() => (
                        <Button
                            block
                            large
                            disabled={loadState === 'CART/LOADING'}
                            href={checkoutUrl}
                            onClick={handleCheckout}
                        >
                            {loadState === 'CART/LOADING'
                                ? 'Updating...'
                                : 'Checkout'}
                        </Button>
                    )}
                />
            </React.Fragment>
        ),
        [
            lineItems,
            subtotalPrice,
            isStale,
            canRenderCart,
            checkoutUrl,
            handleCheckout,
        ]
    );

    return (
        <div className={styles.cart}>
            <Helmet>
                <title>Cart</title>
            </Helmet>
            <Header headline="Cart" />
            {isLoading || isCheckingOut ? (
                <Loader fullscreen />
            ) : cartEmpty ? (
                renderCartEmpty()
            ) : (
                renderCart()
            )}
        </div>
    );
}

Cart.autoload = false;

export default Cart;
