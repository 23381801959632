import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Button from 'stillnovel/components/UI/Button';

import * as styles from './NavArrows.module.css';

const NavArrows = ({ className, buttons, ...props }) => (
    <nav className={cx(styles['nav-arrows'], className)} {...props}>
        {buttons.map((btn, i) => {
            const { to, onClick, disabled, label, targetName } = btn;
            return (
                <Button
                    key={i}
                    to={to}
                    disabled={disabled}
                    className={cx(targetName ? targetName : '')}
                    block
                    outline={i === 0}
                    small
                    onClick={onClick}
                >
                    {i === 0 ? label || 'Prev' : label || 'Next'}
                </Button>
            );
        })}
    </nav>
);

NavArrows.propTypes = {
    className: PropTypes.string,
    buttons: PropTypes.array,
    to: PropTypes.array,
};

export default NavArrows;
