import React from 'react';
import PropTypes from 'prop-types';

import Input from './Input';

import * as styles from './Input.module.css';

const FieldSelect = ({
    children,
    className,
    id,
    input,
    meta: { touched, error },
    name,
    stretch,
    theme,
}) => {
    return (
        <Input
            {...input}
            className={className}
            id={id}
            name={name}
            stretch={stretch}
            type="select"
            touched={touched}
            error={error}
            theme={theme}
        >
            {children}
        </Input>
    );
};

FieldSelect.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    id: PropTypes.string,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    name: PropTypes.string,
    stretch: PropTypes.bool,
    theme: PropTypes.oneOf(Object.keys(styles)),
};

export default FieldSelect;
