import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';

// This component requires Contentful's Image API
// Inspiration: https://www.contentful.com/blog/2019/10/31/webp-source-sets-images-api/

const defaultImageWidth = 640;
const contentfulImageMaxWidth = 2560;

const noFallbackImageWarning = `ContentfulImg.js: For optimization purposes, it is *highly recommended* that you set width and height attributes or a fallbackImageWidth prop. This fallbackImageWidth prop currently has a default value of ${defaultImageWidth}px which may not be an optimal value for this image. This value is used if WebP isn't supported (i.e. Safari) and/or you aren't defining a customSources prop. Don't waste precious bytes!`;

const ImgOptimized = memo(
    ({
        alt = '',
        className,
        customSources,
        decoding = 'async',
        draggable = false,
        fallbackImageWidth,
        loading = 'lazy',
        onLoad,
        priority = false,
        src: ogSrc,
        ...props
    }) => {
        !props.width &&
            !fallbackImageWidth &&
            console.warn(noFallbackImageWarning);

        const fallbackWidth = Math.min(
            props.width || fallbackImageWidth || defaultImageWidth,
            contentfulImageMaxWidth
        );
        const getCustomSources = () =>
            customSources.map(
                ({ breakpoint, imageWidth, src: breakpointSrc }, i) => {
                    // Max image values at 2560px to keep Contentful's Image API happy
                    const w = Math.min(imageWidth, contentfulImageMaxWidth);
                    const src = breakpointSrc || ogSrc;
                    return (
                        <Fragment key={i}>
                            <source
                                media={
                                    breakpoint && `(min-width: ${breakpoint}px)`
                                }
                                srcSet={`${src}?w=${w}&fm=webp&q=80`}
                                type="image/webp"
                            />
                            <source
                                media={
                                    breakpoint && `(min-width: ${breakpoint}px)`
                                }
                                srcSet={`${src}?w=${w}&q=90`}
                            />
                        </Fragment>
                    );
                }
            );
        return (
            <picture>
                {customSources?.length > 0 && getCustomSources()}
                <source
                    srcSet={`${ogSrc}?w=${fallbackWidth}&fm=webp&q=80`}
                    type="image/webp"
                />
                <img
                    {...props}
                    alt={alt}
                    src={`${ogSrc}?w=${fallbackWidth}`}
                    className={className}
                    draggable={draggable}
                    loading={priority ? 'eager' : loading}
                    decoding={priority ? 'sync' : decoding}
                    fetchPriority={priority ? 'high' : 'low'}
                    onLoad={onLoad}
                    onError={onLoad}
                />
            </picture>
        );
    }
);

ImgOptimized.displayName = 'ImgOptimized';

ImgOptimized.propTypes = {
    alt: PropTypes.string,
    className: PropTypes.string,
    customSources: PropTypes.array,
    decoding: PropTypes.string,
    draggable: PropTypes.bool,
    fallbackImageWidth: PropTypes.number,
    height: PropTypes.number,
    loading: PropTypes.string,
    onError: PropTypes.func,
    onLoad: PropTypes.func,
    priority: PropTypes.bool,
    src: PropTypes.string.isRequired,
    width: PropTypes.number,
};

export { ImgOptimized };
