import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import CloseButton from 'stillnovel/components/UI/CloseButton';

import * as styles from './LowResolutionTips.module.css';

const LowResolutionTips = ({ onClose }) => {
    return (
        <aside className={styles.root} role="complementary">
            <div className={styles.inner}>
                <CloseButton
                    type="button"
                    className={styles.closeButton}
                    aria-label="Close Tips"
                    onClick={onClose}
                />
                <span
                    className={cx(styles.headline, 'warning-text', 'body1-alt')}
                >
                    ⚠️ Low resolution photo – cropping disabled
                </span>
                <span className={cx(styles.lead, 'body1')}>
                    The key to the best possible quality is finding the original
                    photo (often kept on your computer, the photographer’s
                    online portal, or within your smartphone library).
                </span>
                <details className={styles.details}>
                    <summary className={styles.summary}>
                        <span className="body1-alt">Photo Quality Tips</span>
                    </summary>
                    <ul className={styles.tips}>
                        <li>
                            <span className={cx('body1')}>
                                <u>Social Media:</u> Images from Instagram or
                                Facebook are often compressed and low
                                resolution.
                            </span>
                        </li>
                        <li>
                            <span className={cx('body1')}>
                                <u>Screenshots:</u> Avoid using screenshots;
                                upload the original photo instead.
                            </span>
                        </li>
                        <li>
                            <span className={cx('body1')}>
                                <u>Unsure?</u> We can help! Email us at{' '}
                                <a href="mailto:support@stillnovel.com">
                                    support@stillnovel.com
                                </a>
                                .
                            </span>
                        </li>
                    </ul>
                </details>
            </div>
        </aside>
    );
};

LowResolutionTips.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default LowResolutionTips;
