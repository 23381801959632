import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import * as styles from './Loader.module.css';

const Loader = ({
    align = 'center',
    fullscreen = false,
    inversed = false,
    text = null,
    theme = 'default',
    textTheme = 'body1-alt',
}) => {
    return (
        <span
            className={cx(styles.loader, {
                [styles['loader-center']]: align === 'center',
                [styles['loader-right']]: align === 'right',
                [styles['loader-left']]: align === 'left',
                [styles['loader-fullscreen']]: fullscreen,
                [styles['loader-inversed']]: inversed,
            })}
        >
            <span className={cx(styles['loader-indicator'], styles[theme])} />
            {text && (
                <span className={cx(styles['loader-text'], textTheme)}>
                    {text}
                </span>
            )}
        </span>
    );
};

Loader.propTypes = {
    align: PropTypes.string,
    fullscreen: PropTypes.bool,
    inversed: PropTypes.bool,
    text: PropTypes.string,
    theme: PropTypes.string,
    textTheme: PropTypes.string,
};

export default Loader;
