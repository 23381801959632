import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Field from 'redux-form/es/Field';
import reduxForm from 'redux-form/es/reduxForm';
import { Col, Row } from 'stillnovel/components/UI/Grid';
import FieldInput from 'stillnovel/components/UI/Input/FieldInput';
import FieldSelect from 'stillnovel/components/UI/Input/FieldSelect';
import Form from 'stillnovel/components/UI/Form';
import NavArrows from 'stillnovel/components/UI/NavArrows';

import FormHeader from '../../../FormHeader';
import StepNav from '../../../StepNav';
import validate from '../validate';

const renderGender = (party1Gender, party2Gender) => {
    const map = {
        Male: 'Mr',
        'Non-binary': 'Mx',
        Female: 'Mrs',
        default: '',
    };

    if (
        (party1Gender === 'Female' && party2Gender === 'Male') ||
        (party1Gender === 'Male' && party2Gender === 'Female')
    ) {
        return 'Mr & Mrs';
    }
    return `${map[party1Gender || 'default']} & ${
        map[party2Gender || 'default']
    }`;
};

let Design = ({
    handleSubmit,
    invalid,
    metadata = {},
    previousPage,
    project,
    submitting,
}) => {
    return (
        <Fragment>
            <Form>
                <FormHeader heading="Couple Info" />
                <Row alignment="bottom">
                    <Col>
                        <label htmlFor="party1Gender" className={'form-title'}>
                            #1 Gender
                        </label>
                        <Field
                            id="party1Gender"
                            name="party1Gender"
                            component={FieldSelect}
                            stretch
                        >
                            <option value="" disabled>
                                --
                            </option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Non-binary">Non-binary</option>
                        </Field>
                    </Col>
                    <Col>
                        <label htmlFor="party2Gender" className={'form-title'}>
                            #2 Gender
                        </label>
                        <Field
                            id="party2Gender"
                            name="party2Gender"
                            component={FieldSelect}
                            stretch
                        >
                            <option value="" disabled>
                                --
                            </option>
                            <option value="Female">Female</option>
                            <option value="Male">Male</option>
                            <option value="Non-binary">Non-binary</option>
                        </Field>
                    </Col>
                </Row>
                <Row alignment="bottom">
                    <Col>
                        <label
                            htmlFor="party1FirstName"
                            className={'form-title'}
                        >
                            #1 First Name
                        </label>
                        <Field
                            name="party1FirstName"
                            type="text"
                            component={FieldInput}
                            placeholder=""
                            autoComplete={`${project.id}-party1FirstName`}
                            autoCorrect="off"
                        />
                    </Col>
                    <Col>
                        <label
                            htmlFor="party2FirstName"
                            className={'form-title'}
                        >
                            #2 First Name
                        </label>
                        <Field
                            name="party2FirstName"
                            type="text"
                            component={FieldInput}
                            placeholder=""
                            autoComplete={`${project.id}-party2FirstName`}
                            autoCorrect="off"
                        />
                    </Col>
                </Row>
                <Row alignment="bottom">
                    <Col>
                        <label htmlFor="party1Word" className={'form-title'}>
                            #1 in a word
                        </label>
                        <Field
                            name="party1Word"
                            type="text"
                            component={FieldInput}
                            placeholder="Ex: “Strong”"
                            maxLength="11"
                            autoComplete={`${project.id}-party1FirstName`}
                        />
                    </Col>
                    <Col>
                        <label htmlFor="party2Word" className={'form-title'}>
                            #2 in a word
                        </label>
                        <Field
                            name="party2Word"
                            type="text"
                            component={FieldInput}
                            placeholder="Ex: “Witty”"
                            maxLength="11"
                            autoComplete={`${project.id}-party2Word`}
                        />
                    </Col>
                </Row>
                <Row alignment="bottom">
                    <Col>
                        <label htmlFor="heading" className={'form-title'}>
                            Title
                        </label>
                        <Field
                            id="heading"
                            name="heading"
                            component={FieldSelect}
                            stretch
                        >
                            <option value="" disabled>
                                --
                            </option>
                            <option value="Use gender">
                                {renderGender(
                                    metadata.party1Gender,
                                    metadata.party2Gender
                                )}{' '}
                                (use gender)
                            </option>
                            <option value="You/Me">You & Me</option>
                            <option value="Use initials">Use initials</option>
                        </Field>
                    </Col>
                </Row>
            </Form>
            <StepNav>
                <NavArrows
                    buttons={[
                        { onClick: previousPage },
                        {
                            onClick: handleSubmit,
                            disabled: invalid || submitting,
                        },
                    ]}
                />
            </StepNav>
        </Fragment>
    );
};

Design.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
    metadata: PropTypes.object,
    previousPage: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    submitting: PropTypes.bool,
};

Design = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
    touchOnChange: true,
    validate,
})(Design);

export default Design;
