import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import Button from 'stillnovel/components/UI/Button';
import AuthTrigger from 'stillnovel/components/App/Auth/Trigger';
import SvgIcon from 'stillnovel/components/UI/SvgIcon';
import useScrollDirection from 'core/hooks/useScrollDirection';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import getConfig from 'core/config';

import * as styles from './Subnav.module.css';

const AccountSubnav = ({ forceShow }) => {
    const scrollDirection = useScrollDirection();
    const hide = !forceShow ? scrollDirection === 'down' : false;
    const location = useLocation();
    const user = useSelector(state => state.auth.user);
    const cartItemCount = useSelector(
        state => state.cart?.lineItems?.length || 0
    );

    const guestUser = user && user.role === 'guest';

    return (
        <nav
            className={cx(styles.root, {
                [styles.hide]: hide,
            })}
        >
            <div className={styles.inner}>
                <Button
                    className={cx(styles.link, 'caption')}
                    to={`${getConfig('marcomBaseUrl')}/products`}
                >
                    <span className={styles.icon}>
                        <SvgIcon iconType="list" />
                    </span>
                    Explore
                </Button>
                <Button
                    className={cx(
                        styles.link,
                        'caption',
                        location.pathname === '/' && styles.active
                    )}
                    to="/"
                >
                    <span className={styles.icon}>
                        <SvgIcon iconType="collection" />
                    </span>
                    Projects
                </Button>

                {cartItemCount > 0 && (
                    <Button
                        className={cx(
                            styles.link,
                            'caption',
                            location.pathname === '/cart' && styles.active
                        )}
                        to="/cart"
                    >
                        <span className={styles.icon}>
                            <SvgIcon iconType="bag" />
                        </span>
                        Cart ({cartItemCount})
                    </Button>
                )}
                {guestUser ? (
                    <AuthTrigger>
                        <Button
                            className={cx(styles.link, 'caption', {
                                [styles.active]: location.pathname === '/login',
                            })}
                        >
                            <span className={styles.icon}>
                                <SvgIcon iconType="badge" />
                            </span>
                            Login
                        </Button>
                    </AuthTrigger>
                ) : (
                    <Button
                        className={cx(styles.link, 'caption', {
                            [styles.active]: location.pathname === '/login',
                        })}
                        to={getConfig('shopifyAccountLink')}
                    >
                        <span className={styles.icon}>
                            <SvgIcon iconType="badge" />
                        </span>
                        Account
                    </Button>
                )}
            </div>
        </nav>
    );
};

AccountSubnav.propTypes = {
    forceShow: PropTypes.bool,
};

export default AccountSubnav;
