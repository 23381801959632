// extracted by mini-css-extract-plugin
var _1 = "yIEAL4";
var _2 = "goOonS";
var _3 = "HdoKZc";
var _4 = "ykcVp3";
var _5 = "EBdRa9";
var _6 = "u7XPLF";
var _7 = "c10FnI";
var _8 = "shMFiV";
export { _1 as "closeButton", _2 as "details", _3 as "headline", _4 as "inner", _5 as "lead", _6 as "root", _7 as "summary", _8 as "tips" }
