import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import * as styles from './Row.module.css';

const Row = ({ children, className, tag = 'span', disabled }) => {
    const TagName = tag;
    const classNames = cx(styles.root, className, {
        [styles.disabled]: disabled,
    });
    return <TagName className={classNames}>{children}</TagName>;
};

Row.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    tag: PropTypes.string,
    disabled: PropTypes.bool,
};

export default Row;
