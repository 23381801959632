import _filter from 'lodash/filter';

import { actionTypes, states } from './actions';

const {
    FETCH_REQUESTED,
    FETCH_SUCCEEDED,
    FETCH_FAILED,
    ADD_PROJECT_TO_CART,
    ADD_ITEM,
    ADD_ITEM_ERROR,
    ADD_ITEM_SUCCESS,
    UPDATE_ITEM_QTY,
    UPDATE_ITEM_QTY_ERROR,
    UPDATE_ITEM_QTY_SUCCESS,
    UPDATE_ITEM_QTY_REQUESTED,
    REMOVE_ITEM,
    REMOVE_ITEM_ERROR,
    REMOVE_ITEM_SUCCESS,
    RESET,
    UPDATE_PRODUCTS,
} = actionTypes;

const { UNINITIALIZED, LOADING, LOADED, ERROR } = states;

const initialState = {
    isDirty: false,
    isStale: false,
    lineItems: [],
    loadState: UNINITIALIZED,
};

function cartReducer(state = initialState, action) {
    const cases = {
        [FETCH_REQUESTED]: () => {
            return {
                ...state,
            };
        },
        [FETCH_FAILED]: () => {
            return {
                ...state,
                loadState: ERROR,
            };
        },
        [FETCH_SUCCEEDED]: payload => {
            return {
                ...state,
                ...payload,
                loadState: LOADED,
                isDirty: false,
                isStale: false,
            };
        },
        [UPDATE_ITEM_QTY_REQUESTED]: payload => {
            // Optimistically update the quantity
            const lineItems = state.lineItems.map(item => {
                if (item.id === payload.id) {
                    return {
                        ...item,
                        quantity: payload.qty,
                    };
                }
                return item;
            });

            // Calculate new subtotal
            const subtotalPrice = {
                amount: lineItems
                    .reduce(
                        (sum, item) =>
                            sum +
                            Number(item.variant.price.amount) * item.quantity,
                        0
                    )
                    .toString(),
            };

            return {
                ...state,
                lineItems,
                subtotalPrice,
                loadState: LOADING,
            };
        },
        [UPDATE_ITEM_QTY]: payload => {
            // update the single item
            const lineItems = state.lineItems.map(item => {
                if (item.id === payload.id) {
                    return { ...item, quantity: payload.qty };
                }
                return item;
            });
            return {
                ...state,
                lineItems,
                loadState: LOADING,
            };
        },
        [UPDATE_ITEM_QTY_SUCCESS]: payload => {
            return {
                ...state,
                ...payload,
                loadState: LOADED,
            };
        },
        [UPDATE_ITEM_QTY_ERROR]: () => {
            return {
                ...action.payload.state, // prev state
                loadState: LOADED,
            };
        },
        [ADD_PROJECT_TO_CART]: () => {
            return {
                ...state,
                isDirty: true,
            };
        },
        [ADD_ITEM]: () => {
            return {
                ...state,
                loadState: LOADING,
            };
        },
        [ADD_ITEM_SUCCESS]: payload => {
            return {
                ...state,
                ...payload,
                loadState: LOADED,
                isDirty: false,
                isStale: false,
            };
        },
        [ADD_ITEM_ERROR]: () => {
            return {
                ...action.payload.state, // prev state
                loadState: LOADED,
                isDirty: false,
            };
        },
        [REMOVE_ITEM]: payload => {
            return {
                ...payload,
                loadState: LOADING,
            };
        },
        [REMOVE_ITEM_ERROR]: () => {
            return {
                ...action.payload.state, // prev state
                loadState: LOADED,
            };
        },
        [REMOVE_ITEM_SUCCESS]: payload => {
            return {
                ...state,
                ...payload,
                loadState: LOADED,
            };
        },
        [RESET]: () => {
            return { ...initialState };
        },
        default: () => state,
    };

    return (cases[action.type] || cases.default)(action.payload);
}

export default cartReducer;
