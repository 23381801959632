// extracted by mini-css-extract-plugin
var _1 = "cxpkmw";
var _2 = "iTVlwy";
var _3 = "vUiel2";
var _4 = "Ub2piX";
var _5 = "woJDow";
var _6 = "xI6vNr";
var _7 = "jA8MUU";
var _8 = "CJsuWw";
var _9 = "d5NKr7";
var _a = "otih4u";
export { _1 as "loader", _2 as "loader-center", _3 as "loader-fullscreen", _4 as "loader-indicator", _5 as "loader-inversed", _6 as "loader-left", _7 as "loader-right", _8 as "loader-text", _9 as "small", _a as "xsmall" }
