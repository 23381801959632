import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import * as styles from './Cell.module.css';

const Cell = ({ children, className, colSpan = 1, tag = 'span' }) => {
    const TagName = tag;
    const classNames = cx(styles.root, className, styles[`col-${colSpan}`]);
    return <TagName className={classNames}>{children}</TagName>;
};

Cell.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    colSpan: PropTypes.number,
    tag: PropTypes.string,
};

export default Cell;
