import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Field from 'redux-form/es/Field';
import reduxForm from 'redux-form/es/reduxForm';
import { Col, Row } from 'stillnovel/components/UI/Grid';
import FieldInput from 'stillnovel/components/UI/Input/FieldInput';
import FieldSelect from 'stillnovel/components/UI/Input/FieldSelect';
import Form from 'stillnovel/components/UI/Form';
import NavArrows from 'stillnovel/components/UI/NavArrows';
import ImageInput from 'stillnovel/components/UI/Input/ImageInput';
import productsConfig from 'stillnovel/components/App/Project/config';

import FormHeader from '../../../FormHeader';
import ColorSelector from '../../shared/ColorSelector';
import StyleSelector from '../../shared/StyleSelector';
import StepNav from '../../../StepNav';
import validate from '../validate';

const regions = {
    'North America': {
        subregions: [
            'Canada',
            'Dominican Republic',
            'Greenland',
            'Hawaii',
            'Jamaica',
            'Mexico',
            'United States',
        ],
    },
    'Central America': {},
    'South America': {},
    Europe: {
        subregions: ['France', 'Germany', 'Italy', 'Spain', 'United Kingdom'],
    },
    'Middle East': {},
    Africa: {},
    Asia: { subregions: ['China', 'Oceania', 'Southeast Asia'] },
    Antarctica: {},
    'World (multiple regions)': { value: 'World' },
};

let BackgroundForm = ({
    handleSubmit,
    invalid,
    metadata,
    previousPage,
    project,
    submitting,
}) => {
    const config = productsConfig.travel;
    const style = metadata?.style === 'vintage' ? 'vintage' : 'modern';
    const styleConfig = config.styles.find(
        styleConfig => style == styleConfig.styleHandle
    );
    return (
        <Fragment>
            <Form>
                <FormHeader heading="Design Setup" />
                <StyleSelector />
                <Row alignment="bottom">
                    <Col>
                        <label htmlFor="firstName" className={'form-title'}>
                            Title
                        </label>
                        <Field
                            id="heading"
                            name="heading"
                            type="text"
                            component={FieldInput}
                            maxLength={16}
                            placeholder="Ex: “Yosemite” or “California”"
                            autoComplete={`${project.id}-heading`}
                            autoCorrect="off"
                        />
                    </Col>
                </Row>
                <Row alignment="bottom">
                    <Col>
                        <label htmlFor="firstName" className={'form-title'}>
                            Region
                        </label>
                        <Field
                            id="region"
                            name="region"
                            component={FieldSelect}
                            stretch
                        >
                            <option key="none" value="">
                                -- Select a region, country, or state --
                            </option>
                            {Object.keys(regions).map((region, i) => (
                                <Fragment key={`region-option-${i}`}>
                                    <option
                                        value={regions[region].value || region}
                                    >
                                        {region}
                                    </option>
                                    {regions[region].subregions?.map(
                                        (subregion, i) => (
                                            <option
                                                key={`subregion-option-${i}`}
                                                value={subregion}
                                            >
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                {subregion}
                                            </option>
                                        )
                                    )}
                                </Fragment>
                            ))}
                        </Field>
                    </Col>
                </Row>
                <Field
                    name="imageMeta"
                    component={ImageInput}
                    targetWidth={styleConfig.photoTargetWidth}
                    targetHeight={styleConfig.photoTargetHeight}
                />
                <Field
                    name="baseColor"
                    component={ColorSelector}
                    style={metadata.style || 'modern'}
                />
                <span className={'caption'}>
                    *We apply a softening layer over all photos to allow for the
                    design and text to subtly pop over your image.
                </span>
            </Form>
            <StepNav>
                <NavArrows
                    buttons={[
                        { onClick: previousPage, disabled: true },
                        {
                            onClick: handleSubmit,
                            disabled: invalid || submitting,
                        },
                    ]}
                />
            </StepNav>
        </Fragment>
    );
};

BackgroundForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
    metadata: PropTypes.object.isRequired,
    previousPage: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    submitting: PropTypes.bool,
};

BackgroundForm = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
    touchOnChange: true,
    validate,
})(BackgroundForm);

export default BackgroundForm;
