import React, { useEffect, createContext, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import formActions from 'redux-form/es/actions';
import { Helmet } from 'react-helmet';
import {
    useParams,
    useNavigate,
    useLocation,
    useSearchParams,
} from 'react-router-dom';
import { usePrevious, useUnmount } from 'react-use';
import { useFetchByHandle } from 'stillnovel/hooks/useShopify';
import { useWait } from 'stillnovel/hooks/useWait';
import { submit } from 'redux-form';
import {
    fetchProject,
    initProject,
    resetProject,
} from 'stillnovel/store/project/actions';
import { MainLayout } from 'stillnovel/components/layouts';

import NotFoundProject from '../NotFound/NotFoundProject';

import { useKeyboardSaveProject } from './useKeyboardSaveProject';
import config from './config';
import * as styles from './Project.module.css';

export const ProductContext = createContext();

export const ProductConsumer = ProductContext.Consumer;

function useQuery() {
    return Object.fromEntries(new URLSearchParams(useLocation().search));
}

const Project = () => {
    const { handle: handleParam, step } = useParams();
    const query = useQuery();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const wait = useWait();
    const isID = !config[handleParam];

    const project = useSelector(state => state.project);

    useKeyboardSaveProject(project);

    const isProjectLoading = useSelector(state => state.project.isLoading);
    const isError = useSelector(state => state.project.isError);

    const isNewPrev = usePrevious(project.isNew);
    const builderHandle = project?.project?.builderHandle || handleParam;

    const isFormInitialized = useSelector(
        state => !!state.form.project?.registeredFields
    );

    const resolvedBuildHandle = isID
        ? project?.project?.builderHandle
        : builderHandle;

    let productConfig = config[resolvedBuildHandle];

    if (productConfig?.getNewConfig) {
        // Makes projects with old build handles backward compatible
        productConfig = productConfig.getNewConfig(resolvedBuildHandle);
    }

    const product = useFetchByHandle(
        productConfig?.shopifyProductHandle || false
    );

    const isLoaded =
        product && typeof product.error === 'undefined' && !isProjectLoading;

    const isProjectInitialized = !!project.project?.builderHandle;

    useEffect(() => {
        if (product.error) {
            throw new Error(product.error);
        }
    }, [product]);

    useEffect(() => {
        if (isFormInitialized && isLoaded) {
            if (searchParams.get('validate')) {
                // slight delay to make it obvious to usr
                wait(300).then(() => dispatch(submit('project')));
            }
        }
         
    }, [isFormInitialized, isLoaded, searchParams, dispatch]);

    const projectDefaults = useMemo(
        () => ({
            ...productConfig?.projectDefaults,
            ...query,
        }),
        [query]
    );

    const loadProject = id => {
        dispatch(fetchProject(id));
    };

    useEffect(() => {
        if (
            (isProjectInitialized && handleParam && !isProjectLoading) ||
            isID
        ) {
            loadProject(handleParam);
        }
         
    }, []);

    useEffect(() => {
        if (
            isLoaded &&
            typeof product.error === 'undefined' &&
            !isProjectInitialized &&
            !isID
        ) {
            dispatch(
                initProject({
                    shopifyProductHandle: productConfig?.shopifyProductHandle,
                    builderHandle,
                    shopifyProductId: product.id,
                    metadata: projectDefaults,
                })
            );
        }
    }, [
        builderHandle,
        dispatch,
        isID,
        isProjectInitialized,
        isLoaded,
        product,
        productConfig,
        projectDefaults,
    ]);

    useEffect(() => {
        if (isNewPrev === true && project.isNew === false) {
            // Handles url replace once saved *Logged in
            navigate(
                `/project/${project.project.id}${step ? `/${step}` : ''}`,
                {
                    replace: true,
                    state: { scroll: false },
                }
            );
        }
         
    }, [project.isNew, isNewPrev, project.isAnonymous]);

    useUnmount(() => {
        dispatch(formActions.destroy('project'));
        dispatch(resetProject());
    });

    if (
        isID &&
        !isProjectLoading &&
        isError &&
        typeof project?.project?.id === 'undefined'
    ) {
        return (
            <MainLayout>
                <NotFoundProject />
            </MainLayout>
        );
    }

    if (!isLoaded && !isProjectInitialized) {
        return (
            <div className={styles.root}>
                <span className="body1-alt">Loading Project...</span>
            </div>
        );
    }

    // No component found / Unsupported SKU
    if (!productConfig && isLoaded) {
        return (
            <div className={styles.root}>
                <span className="body1-alt">
                    {`No product handle: ${handleParam}`}
                </span>
            </div>
        );
    }

    // Find SKU
    const ProjectBuildComponent = productConfig?.component;

    // No component found / Unsupported SKU
    if (!ProjectBuildComponent) {
        return (
            <div className={styles.root}>
                <span className="body1-alt">
                    {`No Component to handle: ${handleParam}`}
                </span>
            </div>
        );
    }

    return (
        <ProductContext.Provider value={product}>
            <Helmet>
                <title>{productConfig?.parentCategory}</title>
                <meta
                    name="description"
                    content={`Build your own ${productConfig?.parentCategory}.`}
                />
            </Helmet>
            <ProjectBuildComponent
                id={project?.project?.id}
                product={product}
                project={project}
                projectDefaults={projectDefaults}
                isLoading={isProjectLoading}
            />
        </ProductContext.Provider>
    );
};

export default Project;
