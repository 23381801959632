import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './Form.module.css';

const Form = ({ ...props }) => <form className={styles.root} {...props} />;

Form.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Form;
