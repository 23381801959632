import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Field from 'redux-form/es/Field';
import reduxForm from 'redux-form/es/reduxForm';
import StepNav from 'stillnovel/components/App/Project/Builders/ArtPrint/StepNav';
import { Container, Col, Row } from 'stillnovel/components/UI/Grid';
import FieldInput from 'stillnovel/components/UI/Input/FieldInput';
import FieldSelect from 'stillnovel/components/UI/Input/FieldSelect';
import Form from 'stillnovel/components/UI/Form';
import FormHeader from 'stillnovel/components/App/Project/Builders/ArtPrint/FormHeader';
import NavArrows from 'stillnovel/components/UI/NavArrows';
import PlacesAutocomplete from 'stillnovel/components/UI/PlacesAutocomplete';

import validate from '../validate';

let Details = ({
    handleSubmit,
    invalid,
    previousPage,
    project,
    submitting,
}) => {
    const isVintageStyle = project.metadata?.style === 'vintage';
    const charLimit = isVintageStyle ? 30 : 17;

    return (
        <Fragment>
            <Form>
                <FormHeader heading="Details" />
                <Container>
                    <Row>
                        <Col>
                            <label
                                htmlFor="metLocationData"
                                className={'form-title'}
                            >
                                Home City, State
                            </label>
                            <Field
                                name="metLocationData"
                                component={PlacesAutocomplete}
                                placeholder="Ex: “Los Angeles, CA”"
                                autoComplete={`${project.id}-metLocationData`}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label
                                htmlFor="arrivedHomeNumber"
                                className={'form-title'}
                            >
                                Age at Adoption
                            </label>
                            <span>
                                <Field
                                    name="arrivedHomeNumber"
                                    pattern="\d*"
                                    type="number"
                                    min="1"
                                    component={FieldInput}
                                    placeholder="Ex: “2”"
                                    autoComplete={`${project.id}-arrivedHomeNumber`}
                                    autoCorrect="off"
                                    maxLength={charLimit}
                                    inline
                                />
                                &nbsp;&nbsp;
                                <Field
                                    name="arrivedHomePeriod"
                                    component={FieldSelect}
                                    inline
                                >
                                    <option value="" disabled>
                                        --
                                    </option>
                                    <option value="DAY">Day(s) old</option>
                                    <option value="WEEK">Week(s) old</option>
                                    <option value="MONTH">Month(s) old</option>
                                    <option value="YEAR">Year(s) old</option>
                                </Field>
                            </span>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col>
                            <h3 className={'form-title'}>
                                Finish the sentence:
                            </h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <span
                                className={'form-title'}
                                style={{
                                    lineHeight: '2.8',
                                }}
                            >
                                <Field
                                    inline
                                    id="metPronoun"
                                    name="metPronoun"
                                    component={FieldSelect}
                                >
                                    <option value="" disabled>
                                        --
                                    </option>
                                    <option value="We">We</option>
                                    <option value="I">I</option>
                                </Field>{' '}
                                met you{' '}
                                <Field
                                    inline
                                    id="metPlacePreposition"
                                    name="metPlacePreposition"
                                    component={FieldSelect}
                                >
                                    <option value="" disabled>
                                        --
                                    </option>
                                    <option value="at">AT</option>
                                    <option value="in">IN</option>
                                    <option value="on">ON</option>
                                </Field>{' '}
                                <Field
                                    stretch
                                    name="metPlaceName"
                                    component={FieldInput}
                                    placeholder="Ex: “the local rescue”"
                                    autoComplete={`${project.id}-metPlaceName`}
                                    autoCapitalize="off"
                                    size="23"
                                />{' '}
                                AND CHOSE YOU BECAUSE{' '}
                                <Field
                                    stretch
                                    name="choseReason"
                                    component={FieldInput}
                                    placeholder="Ex: “you were so playful”"
                                    autoComplete={`${project.id}-choseReason`}
                                    autoCapitalize="off"
                                    size="27"
                                />
                            </span>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col>
                            <label
                                htmlFor="description"
                                className={'form-title'}
                            >
                                In a word, You are
                            </label>
                            <Field
                                name="description"
                                type="text"
                                component={FieldInput}
                                placeholder="Ex: “A cuddler”"
                                autoComplete={`${project.id}-description`}
                                autoCorrect="off"
                                maxLength={30}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label
                                htmlFor="aboutSleep"
                                className={'form-title'}
                            >
                                You sleep
                            </label>
                            <Field
                                name="aboutSleep"
                                type="text"
                                component={FieldInput}
                                placeholder="Ex: “Under the covers”"
                                autoComplete={`${project.id}-aboutSleep`}
                                autoCorrect="off"
                                maxLength={30}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label
                                htmlFor="aboutAlways"
                                className={'form-title'}
                            >
                                You always
                            </label>
                            <Field
                                name="aboutAlways"
                                type="text"
                                component={FieldInput}
                                placeholder="Ex: “Find a warm lap”"
                                autoComplete={`${project.id}-aboutAlways`}
                                autoCorrect="off"
                                maxLength={30}
                            />
                        </Col>
                    </Row>
                </Container>
            </Form>
            <StepNav>
                <NavArrows
                    buttons={[
                        { onClick: previousPage },
                        {
                            onClick: handleSubmit,
                            disabled: invalid || submitting,
                        },
                    ]}
                />
            </StepNav>
        </Fragment>
    );
};

Details.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
    previousPage: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    submitting: PropTypes.bool,
};

Details = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
    touchOnChange: true,
    validate,
})(Details);

export default Details;
