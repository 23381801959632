import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Field from 'redux-form/es/Field';
import reduxForm from 'redux-form/es/reduxForm';
import StepNav from 'stillnovel/components/App/Project/Builders/ArtPrint/StepNav';
import { Container, Col, Row } from 'stillnovel/components/UI/Grid';
import DateInput from 'stillnovel/components/UI/Input/DateInput';
import FieldInput from 'stillnovel/components/UI/Input/FieldInput';
import Form from 'stillnovel/components/UI/Form';
import FieldSelect from 'stillnovel/components/UI/Input/FieldSelect';
import FormHeader from 'stillnovel/components/App/Project/Builders/ArtPrint/FormHeader';
import NavArrows from 'stillnovel/components/UI/NavArrows';

import validate from '../validate';

const weatherOptions = [
    'OMIT',
    'Perfect',
    'Warm',
    'Sunny',
    'Brisk',
    'Chilly',
    'Rainy',
    'Stormy',
];

const timeOfDayOptions = ['OMIT', 'Morning', 'Afternoon', 'Evening', 'Night'];

let Details = ({ handleSubmit, invalid, previousPage, submitting }) => {
    return (
        <Fragment>
            <Form>
                <FormHeader heading="Details" />
                <Container>
                    <Row>
                        <Col>
                            <label className={'form-title'} htmlFor="birthDate">
                                Birth Date
                            </label>
                            <Field
                                name="birthDate"
                                type="select"
                                showErrorText
                                component={DateInput}
                                showTime={false}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label htmlFor="timeOfDay" className={'form-title'}>
                                Time of Day
                            </label>
                            <Field
                                id="timeOfDayStatement"
                                name="timeOfDayStatement"
                                component={FieldSelect}
                                stretch
                            >
                                <option value="" disabled>
                                    --
                                </option>
                                {timeOfDayOptions.map((option, i) => (
                                    <option key={i} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </Field>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label htmlFor="weather" className={'form-title'}>
                                Weather
                            </label>
                            <Field
                                id="weatherStatement"
                                name="weatherStatement"
                                component={FieldSelect}
                                stretch
                            >
                                <option value="" disabled>
                                    --
                                </option>
                                {weatherOptions.map((option, i) => (
                                    <option key={i} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </Field>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label htmlFor="thought" className={'form-title'}>
                                A Thought (optional)
                            </label>
                            <Field
                                stretch
                                id="thought"
                                name="thought"
                                type="textarea"
                                component={FieldInput}
                                maxLength="124"
                                showErrorText
                                placeholder="A sentence or two about what you think makes this person special."
                            />
                        </Col>
                    </Row>
                </Container>
            </Form>
            <StepNav>
                <NavArrows
                    buttons={[
                        { onClick: previousPage },
                        {
                            onClick: handleSubmit,
                            disabled: invalid || submitting,
                        },
                    ]}
                />
            </StepNav>
        </Fragment>
    );
};

Details.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
    previousPage: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
};

Details = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
    touchOnChange: true,
    validate,
})(Details);

export default Details;
