import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Field from 'redux-form/es/Field';
import reduxForm from 'redux-form/es/reduxForm';
import StepNav from 'stillnovel/components/App/Project/Builders/ArtPrint/StepNav';
import { Container, Col, Row } from 'stillnovel/components/UI/Grid';
import DateInput from 'stillnovel/components/UI/Input/DateInput';
import FieldInput from 'stillnovel/components/UI/Input/FieldInput';
import FieldRadio from 'stillnovel/components/UI/Input/FieldRadio';
import Form from 'stillnovel/components/UI/Form';
import FieldSelect from 'stillnovel/components/UI/Input/FieldSelect';
import FormHeader from 'stillnovel/components/App/Project/Builders/ArtPrint/FormHeader';
import NavArrows from 'stillnovel/components/UI/NavArrows';
import TextPreview from 'stillnovel/components/UI/TextPreview';

import NoteToYouStyle01 from '@stillnovel/artistry/lib/NoteToYouStyle01';
import NoteToYouStyle02 from '@stillnovel/artistry/lib/NoteToYouStyle02';

import validate from '../validate';

import * as styles from './Details.module.css';

const weatherOptions = [
    'Perfect',
    'Warm',
    'Sunny',
    'Brisk',
    'Chilly',
    'Rainy',
    'Stormy',
    'OMIT',
];

const timeOfDayOptions = ['MORNING', 'AFTERNOON', 'EVENING', 'Omit'];
const dateHeaderOptionsOptions = ['Adoption Date', 'Birth Date'];

const birthDateFormatOptions = ['Roman Numerals', 'Standard Date'];

const titleOptions = ['A NOTE TO YOU', 'MY LOVE', 'YOU & ME', 'CUSTOM'];

let Details = ({
    handleSubmit,
    invalid,
    previousPage,
    submitting,
    project,
}) => {
    const {
        metadata: {
            firstName,
            nameMeaning,
            whoChoseTheName,
            choseItBecause,
            weatherThatDay,
            typeOfBirth,
            timeOfDay,
            birthDate,
            adoptionDate,
            birthStoryHowMany,
            birthStoryTimeUnits,
            birthStoryChoice,
            whoWillNeverForget,
            weWillNeverForget,
            adoptionStoryTimeUnits,
            adoptionStoryChoice,
            subject,
            customField,
            style,
            titleOfStory,
            birthDateFormat,
        },
    } = project;

    const birthDateParsed = birthDate ? moment.parseZone(birthDate) : false;
    const adoptionDateParsed = adoptionDate
        ? moment.parseZone(adoptionDate)
        : false;

    const renderSentence =
        style === 'Style 01'
            ? NoteToYouStyle01.renderSentence
            : NoteToYouStyle02.renderSentence;

    const addEmsForPreviewText = true;

    const previewSentence = renderSentence(
        firstName,
        nameMeaning,
        whoChoseTheName,
        choseItBecause,
        weatherThatDay,
        typeOfBirth,
        birthDateParsed,
        timeOfDay,
        birthStoryHowMany,
        birthStoryTimeUnits,
        birthStoryChoice,
        whoWillNeverForget,
        weWillNeverForget,
        adoptionDate,
        adoptionDateParsed,
        adoptionStoryTimeUnits,
        adoptionStoryChoice,
        subject,
        customField,
        addEmsForPreviewText
    );

    return (
        <Fragment>
            <Form>
                <FormHeader heading="Details" />
                <Container>
                    <Row
                        className={
                            subject !== 'Custom Note'
                                ? styles.block
                                : styles.hide
                        }
                        alignment="bottom"
                    >
                        <Col>
                            <label htmlFor="firstName" className={'form-title'}>
                                Name
                            </label>
                            <Field
                                id="firstName"
                                name="firstName"
                                type="text"
                                component={FieldInput}
                                placeholder="First"
                                autoComplete={`${project.id}-firstName`}
                                autoCorrect="off"
                            />
                        </Col>
                        <Col>
                            <Field
                                name="middleName"
                                type="text"
                                component={FieldInput}
                                placeholder="Middle (optional)"
                                autoComplete={`${project.id}-middleName`}
                                autoCorrect="off"
                            />
                        </Col>
                        <Col>
                            <Field
                                name="lastName"
                                type="text"
                                component={FieldInput}
                                placeholder="Last (optional)"
                                autoComplete={`${project.id}-lastName`}
                                autoCorrect="off"
                            />
                        </Col>
                    </Row>
                    <Row
                        className={
                            subject === 'Custom Note'
                                ? styles.block
                                : styles.hide
                        }
                    >
                        <Col>
                            <label
                                htmlFor="titleOfStory"
                                className={'form-title'}
                            >
                                Title
                            </label>
                            <Field
                                hidden={subject !== 'Custom Note'}
                                id="titleOfStory"
                                name="titleOfStory"
                                component={FieldSelect}
                                stretch
                            >
                                {titleOptions.map((option, i) => (
                                    <option key={i} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </Field>
                        </Col>
                    </Row>

                    <Row
                        className={
                            subject === 'Custom Note' &&
                            titleOfStory === 'CUSTOM'
                                ? styles.block
                                : styles.hide
                        }
                    >
                        <Col>
                            <label
                                htmlFor="titleCustom"
                                className={'form-title'}
                            >
                                Custom Title
                            </label>
                            <Field
                                stretch
                                id="titleCustom"
                                name="titleCustom"
                                type="textarea"
                                component={FieldInput}
                                maxLength="50"
                                showErrorText
                                placeholder="A sentence or two about what you think makes this person special."
                            />
                        </Col>
                    </Row>

                    <Row
                        className={
                            subject !== 'Custom Note'
                                ? styles.block
                                : styles.hide
                        }
                    >
                        <Col>
                            <label className={'form-title'} htmlFor="birthDate">
                                Birth Date
                            </label>
                            <Field
                                name="birthDate"
                                type="select"
                                showErrorText
                                component={DateInput}
                                showTime={false}
                            />
                        </Col>
                    </Row>
                    <Row
                        className={
                            subject === 'Adoption Story'
                                ? styles.block
                                : styles.hide
                        }
                    >
                        <Col>
                            <label className={'form-title'} htmlFor="birthDate">
                                Adoption Date
                            </label>
                            <Field
                                name="adoptionDate"
                                type="select"
                                showErrorText
                                component={DateInput}
                                showTime={false}
                            />
                        </Col>
                    </Row>

                    <Row
                        className={
                            subject === 'Adoption Story'
                                ? styles.block
                                : styles.hide
                        }
                    >
                        <Col>
                            <label
                                htmlFor="dateHeaderOptions"
                                className={'form-title'}
                            >
                                Title Date
                            </label>
                            <Field
                                id="dateHeaderOptions"
                                name="dateHeaderOptions"
                                component={FieldSelect}
                                stretch
                            >
                                <option value="" disabled>
                                    --
                                </option>
                                {dateHeaderOptionsOptions.map((option, i) => (
                                    <option key={i} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </Field>
                        </Col>
                    </Row>

                    <Row
                        className={
                            subject !== 'Custom Note'
                                ? styles.block
                                : styles.hide
                        }
                    >
                        <Col>
                            <label
                                htmlFor="birthDateFormat"
                                className={'form-title'}
                            >
                                Date Format
                            </label>
                        </Col>
                        <div className={styles.flex}>
                            {birthDateFormatOptions.map((value, i) => (
                                <Col key={value} colSpan={i + 1}>
                                    <Field
                                        checked={birthDateFormat === value}
                                        name="birthDateFormat"
                                        component={FieldRadio}
                                        label={value}
                                        id={value}
                                    />
                                </Col>
                            ))}
                        </div>
                    </Row>
                    <Row
                        className={
                            subject === 'Birth Story'
                                ? styles.flex
                                : styles.hide
                        }
                    >
                        <Col>
                            <label htmlFor="timeOfDay" className={'form-title'}>
                                Birth Time Of Day
                            </label>
                            <Field
                                id="timeOfDay"
                                name="timeOfDay"
                                component={FieldSelect}
                                stretch
                            >
                                <option value="" disabled>
                                    --
                                </option>
                                {timeOfDayOptions.map((option, i) => (
                                    <option key={i} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </Field>
                        </Col>
                    </Row>
                    <Row
                        className={
                            subject === 'Birth Story'
                                ? styles.block
                                : styles.hide
                        }
                    >
                        <Col>
                            <label
                                htmlFor="weatherThatDay"
                                className={'form-title'}
                            >
                                The Weather That Day Was
                            </label>
                            <Field
                                id="weatherThatDay"
                                name="weatherThatDay"
                                component={FieldSelect}
                                stretch
                            >
                                <option value="" disabled>
                                    --
                                </option>
                                {weatherOptions.map((option, i) => (
                                    <option key={i} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </Field>
                        </Col>
                    </Row>

                    <Row
                        className={
                            subject === 'Name Meaning'
                                ? styles.block
                                : styles.hide
                        }
                    >
                        <Col>
                            <label
                                htmlFor="nameMeaning"
                                className={'form-title'}
                            >
                                Name Meaning (In a word or two)
                            </label>
                            <Field
                                stretch
                                id="nameMeaning"
                                name="nameMeaning"
                                type="text"
                                component={FieldInput}
                                maxLength="50"
                                showErrorText
                                placeholder="Ex: “Brave”"
                            />
                        </Col>
                    </Row>
                    <Row
                        className={
                            subject === 'Name Meaning'
                                ? styles.block
                                : styles.hide
                        }
                    >
                        <Col>
                            <label
                                htmlFor="whoChoseTheName"
                                className={'form-title'}
                            >
                                Who Chose The Name
                            </label>
                            <Field
                                stretch
                                id="whoChoseTheName"
                                name="whoChoseTheName"
                                type="text"
                                component={FieldInput}
                                maxLength="40"
                                showErrorText
                                placeholder="Ex: “Mom” or “We”"
                            />
                        </Col>
                    </Row>
                    <Row
                        className={
                            subject === 'Name Meaning'
                                ? styles.block
                                : styles.hide
                        }
                    >
                        <Col>
                            <label
                                htmlFor="choseItBecause"
                                className={'form-title'}
                            >
                                Chose It Because
                            </label>
                            <Field
                                stretch
                                id="choseItBecause"
                                name="choseItBecause"
                                type="textarea"
                                component={FieldInput}
                                maxLength={style === 'Style 01' ? '140' : '100'}
                                showErrorText
                                placeholder="Ex: “It was your great grandmother's name and you look just like her”"
                            />
                        </Col>
                    </Row>
                    <Row
                        className={
                            subject === 'Custom Note'
                                ? styles.block
                                : styles.hide
                        }
                    >
                        <Col>
                            <label
                                htmlFor="customField"
                                className={'form-title'}
                            >
                                Custom Note
                            </label>
                            <Field
                                stretch
                                id="customField"
                                name="customField"
                                type="textarea"
                                component={FieldInput}
                                maxLength={style === 'Style 01' ? '240' : '140'}
                                showErrorText
                                placeholder="Ex: “A quote, note, poem, song lyrics or freeform sentence of your choosing.”"
                            />
                        </Col>
                    </Row>
                    <Row
                        className={
                            subject === 'Name Meaning'
                                ? styles.block
                                : styles.hide
                        }
                    >
                        <Col>
                            <TextPreview string={`${previewSentence}`} />
                        </Col>
                    </Row>
                    <Row
                        className={
                            style === 'Style 01' && subject === 'Custom Note'
                                ? styles.block
                                : styles.hide
                        }
                    >
                        <Col>
                            <label
                                htmlFor="photoCaption"
                                className={'form-title'}
                            >
                                Optional Signature Or Caption
                            </label>
                            <Field
                                stretch
                                id="photoCaption"
                                name="photoCaption"
                                type="text"
                                component={FieldInput}
                                maxLength="60"
                                showErrorText
                                placeholder="Ex: “June 2022” or “Yosemite” or “Harper Lee”"
                            />
                        </Col>
                    </Row>
                    <Row
                        className={
                            style === 'Style 01' && subject === 'Name Meaning'
                                ? styles.block
                                : styles.hide
                        }
                    >
                        <Col>
                            <label
                                htmlFor="bylineOrCaption"
                                className={'form-title'}
                            >
                                Optional Signature Or Caption
                            </label>
                            <Field
                                stretch
                                id="bylineOrCaption"
                                name="bylineOrCaption"
                                type="text"
                                component={FieldInput}
                                maxLength="60"
                                showErrorText
                                placeholder="Ex: “Mom & Dad”"
                            />
                        </Col>
                    </Row>
                </Container>
            </Form>
            <StepNav>
                <NavArrows
                    buttons={[
                        { onClick: previousPage },
                        {
                            onClick: handleSubmit,
                            disabled: invalid || submitting,
                        },
                    ]}
                />
            </StepNav>
        </Fragment>
    );
};

Details.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
    previousPage: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    project: PropTypes.object.isRequired,
};

Details = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
    touchOnChange: true,
    validate,
})(Details);

export default Details;
