import { put, takeEvery, select, call } from 'redux-saga/effects';
import 'aws-sdk/lib/credentials/cognito_identity_credentials';
import { performLogin } from 'stillnovel/utils/auth';
import {
    EmailVerificationException,
    TokenVerificationException,
} from 'core/services/exceptions';
import SN from 'stillnovel/services';
import * as Sentry from '@sentry/react';
import { event } from 'react-ga';

import { getCartItems, resetCart } from '../cart/actions';
import { resetProjects, getProjects } from '../projects/actions';

import {
    authenticated,
    loggingIn,
    states as authStates,
    actionTypes,
} from './actions';

export const getAuthState = state => state.auth;

/**
 * transition auth state changes
 */

function* authObserver() {
    const { state } = yield select(getAuthState);
    if (state === authStates.AUTHENTICATED) {
        yield put(loggingIn());
    }
    if (state === authStates.LOGGING_IN) {
        const action = yield call(performLogin);

        yield put(action);

        const { user } = yield select(getAuthState);

        yield put(getProjects());
        yield put(getCartItems(user.id));

        if (window.zE && user?.email) {
            window.zE(() => {
                window.zE.identify({
                    email: user.email,
                });
            });
        }
    }
}

function* verifyAccount(action) {
    const {
        payload: { attributes, meta },
    } = action;
    try {
        const user = yield call(() => SN.auth.verify(attributes));
        yield put(authenticated(user));
        if (typeof meta?.callback === 'function') {
            yield call(meta?.callback);
        }
    } catch (e) {
        if (e instanceof EmailVerificationException) {
            yield put({
                type: actionTypes.ACCOUNT_VERIFICATION_ERROR,
                error: e.message,
            });
        } else {
            yield put({
                type: actionTypes.ACCOUNT_VERIFICATION_ERROR,
                error: '⚠️ An unknown error has occurred',
            });
        }
    }
}

function* resetPassword(action) {
    const { attributes } = action;
    try {
        yield call(() => SN.auth.resetPassword(attributes));
        yield put(loggingIn());
        yield call(history.replace, `/`);
    } catch (e) {
        if (e instanceof TokenVerificationException) {
            yield put({
                type: actionTypes.PASSWORD_RESET_ERROR,
                error: e.message,
            });
        } else {
            yield put({
                type: actionTypes.PASSWORD_RESET_ERROR,
                error: '⚠️ An unknown error has occurred',
            });
        }
    }
}

function* verifyEmail(action) {
    const {
        payload: {
            attributes,
            meta: { callback },
        },
    } = action;
    try {
        yield call(() => SN.auth.verifyEmail(attributes));
        yield put(loggingIn());
        yield call(callback);
    } catch (e) {
        if (e instanceof EmailVerificationException) {
            yield put({
                type: actionTypes.EMAIL_VERIFICATION_ERROR,
                error: e.message,
            });
        } else {
            yield put({
                type: actionTypes.EMAIL_VERIFICATION_ERROR,
                error: '⚠️ An unknown error has occurred',
            });
        }
    }
}

function* login() {
    const { user } = yield select(getAuthState);

    Sentry.getCurrentScope().setUser({
        id: user.id,
        email: user.email,
        username: user.email,
    });

    window._learnq.push([
        'identify',
        {
            // Change the line below to dynamically print the user's email.
            $email: user.email,

            $first_name: user.firstName,

            $last_name: user.lastName,
        },
    ]);

    // Send default GA event
    event({
        category: 'engagement',
        action: 'login',
    });
}

function* logout() {
    Sentry.getCurrentScope().setUser(null);

    yield call(() => SN.auth.logout());

    yield put(resetCart());
    yield put(resetProjects());
    yield put(loggingIn());
}

export default [
    takeEvery(action => /^AUTH\//.test(action.type), authObserver),
    takeEvery(actionTypes.ACCOUNT_VERIFICATION_REQUESTED, verifyAccount),
    takeEvery(actionTypes.EMAIL_VERIFICATION_REQUESTED, verifyEmail),
    takeEvery(actionTypes.PASSWORD_RESET_REQUESTED, resetPassword),
    takeEvery(actionTypes.LOGOUT, logout),
    takeEvery(actionTypes.LOGIN, login),
];
